export const columns = [
  {
    title: "Référence",
    dataIndex: "reference",
    key: "reference",
    sorter: (a, b) => a.lot_reference.localeCompare(b.lot_reference),
    width: "",
  },
  {
    title: "Proprietaire",
    dataIndex: "proprietaire",
    key: "proprietaire",
    width: "",
  },
  {
    title: "Type",
    dataIndex: "type_lot",
    key: "type_lot",
    width: "",
  },
  {
    title: "Nuits confiées",
    dataIndex: "nuits_confiees",
    key: "nuits_confiees",
    align: "right",
    width: "",
  },
  {
    title: "Nuits louées",
    dataIndex: "nuits_louees",
    key: "nuits_louees",
    align: "right",
    width: "",
  },
  {
    title: "Occupation",
    dataIndex: "occupation",
    key: "occupation",
    render: (occupation) => <>{occupation}%</>,
    width: "",
  },
  {
    title: "Nombre de locations",
    dataIndex: "nbr_locations",
    key: "nbr_locations",
    align: "right",
    width: "",
  },
  {
    title: "Durée moyenne",
    dataIndex: "duree_moyenne",
    key: "duree_moyenne",
    align: "right",
    width: "",
  },
  {
    title: "Adultes",
    dataIndex: "adultes",
    key: "adultes",
    render: (adultes) => <>{adultes}%</>,
    width: "",
  },
  {
    title: "Enfants bébés",
    dataIndex: "enfants_bebes",
    key: "enfants_bebes",
    render: (enfants_bebes) => <>{enfants_bebes}%</>,
    width: "",
  },
  {
    title: "C.A. prop.",
    dataIndex: "montant_total",
    key: "montant_total",
    render: (montant_total) =>
      parseFloat(montant_total).toLocaleString("fr-FR", {
        style: "currency",
        currency: "EUR",
      }),
    width: "",
  },
  {
    title: "Taux Hono.",
    dataIndex: "taux_honoraires",
    key: "taux_honoraires",
    render: (taux_honoraires) => <>{taux_honoraires ? taux_honoraires : 0}%</>,
    width: "",
  },
  {
    title: "Hono. Prop.",
    dataIndex: "montant_proprietaire",
    key: "montant_proprietaire",
    render: (montant_proprietaire) =>
      parseFloat(montant_proprietaire).toLocaleString("fr-FR", {
        style: "currency",
        currency: "EUR",
      }),
    width: "",
  },
  {
    title: "Hono. autres",
    dataIndex: "montant_agence",
    key: "montant_agence",
    render: (montant_agence) =>
      parseFloat(montant_agence).toLocaleString("fr-FR", {
        style: "currency",
        currency: "EUR",
      }),
    width: "",
  },
  {
    title: "Total Hono.",
    dataIndex: "montant_honoraires",
    key: "montant_honoraires",
    render: (montant_honoraires) =>
      parseFloat(montant_honoraires).toLocaleString("fr-FR", {
        style: "currency",
        currency: "EUR",
      }),
    width: "",
  },
];

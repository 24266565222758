import { adaptedToAdresse, adresseToAdapted } from "./adresseAdapter";
import { groupementToAdapted } from "./groupementAdapter";

export function societesToAdapted(items) {
  return items
    ? items.map((item) => {
        return societeToAdapted(item);
      })
    : null;
}

export function societeToAdapted(item) {
  //Resource
  return item
    ? {
        societe_id: item.id,
        societe_num_client: item.num_client,
        societe_raison_sociale: item.raison_sociale,
        societe_telephone: item.telephone,
        societe_email: item.email,
        societe_adresse: adresseToAdapted(item.adresse),
        societe_nom_court: item.nom_court,
        societe_telephone_2: item.telephone_2,
        societe_site_web: item.site_web,
        societe_zone_libre_1: item.zone_libre_1,
        societe_zone_libre_2: item.zone_libre_2,
        societe_zone_libre_3: item.zone_libre_3,
        societe_zone_libre_4: item.zone_libre_4,
        societe_zone_libre_5: item.zone_libre_5,
        societe_groupement: groupementToAdapted(item.groupement),
        societe_compteur_signatures: item.compteur_signatures,
        societe_beds24: item.beds24,
        societe_chemin_logo: item.chemin_logo,
        societe_logo_width: item.logo_width,
        societe_logo_height: item.logo_height,
        societe_dossier_id: item.dossier_id,
      }
    : null;
}

export function adaptedToSociete(item) {
  //Model
  const societe = item
    ? {
        num_client: item.societe_num_client,
        raison_sociale: item.societe_raison_sociale,
        telephone: item.societe_telephone,
        email: item.societe_email,
        adresse_id: item.societe_adresse_id,
        nom_court: item.societe_nom_court,
        telephone_2: item.societe_telephone_2,
        site_web: item.societe_site_web,
        zone_libre_1: item.societe_zone_libre_1,
        zone_libre_2: item.societe_zone_libre_2,
        zone_libre_3: item.societe_zone_libre_3,
        zone_libre_4: item.societe_zone_libre_4,
        zone_libre_5: item.societe_zone_libre_5,
        groupement_id: item.societe_groupement_id,
        compteur_signatures: item.societe_compteur_signatures,
        beds24: item.societe_beds24,
        dossier_id: item.societe_dossier_id,
      }
    : null;

  const adresse = item ? adaptedToAdresse(item) : null;

  return item
    ? {
        ...societe,
        ...adresse,
      }
    : null;
}

import React, { Component } from "react";
import ReactDOMServer from "react-dom/server";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Popconfirm,
  Progress,
  Row,
  Select,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { EyeOutlined, QuestionOutlined, SearchOutlined, SyncOutlined } from "@ant-design/icons";

import { toast } from "react-toastify";

import ListeRedditionPDF from "../Pdf/ListeRedditionPDF";
import RelevePDF from "../Pdf/RelevePDF";

import CustomSelect from "../CustomFormItem/Select";
import CustomDatePicker from "../CustomFormItem/DatePicker";
import FormMailMultiple from "../FormMailMultiple";

import { round } from "../../utils/tools";

import dayjs from "dayjs";
import { HttpMethod, initFetch } from "../../utils/fetcher";
import { openPdf } from "../../utils/utils";

const { Option, OptGroup } = Select;
const { Text } = Typography;

class FormReddition extends Component {
  constructor(props) {
    super(props);

    this.state = {
      preparation: false,
      loading: false,
      showProgress: false,
      percent: 0,
      societe: null,
      journal: null,
      detaillee: false,
      longueDuree: false,
      typePaiement: "1",
      commencees: true,
      reglees: true,
      dateButoir: dayjs().format("DD/MM/YYYY"),
      dateButoirDepenses: dayjs().format("DD/MM/YYYY"),
      dateReleve: dayjs().format("DD/MM/YYYY"),
      lignesReddition: [],
      selectedRowKeys: [],
      selectedRows: [],
      selectedRowRedditionKeys: [],
      selectedRowsReddition: [],
      modalListe: false,
      modalPdf: false,
      modalMail: false,
      idPdf: null,
      validation: false,
    };
  }

  formRef = React.createRef();

  componentDidMount() {
    this.init();
  }

  init = () => {
    if (this.props.societes.length === 1) {
      this.setState({ societe: this.props.societes[0].societe_id });
    }

    this.formRef.current.setFieldsValue({
      societe_id: this.props.societes[0].societe_raison_sociale,
      journal_id: this.props.journals.filter((item) => item.journal_de_banque === true)[0]
        .journal_id,
      centralises: true,
      detaillee: this.props.parametre.parametre_reddition_detaillee,
    });

    this.setState({
      journal: this.props.journals.filter((item) => item.journal_de_banque === true)[0].journal_id,
      detaillee: this.props.parametre.parametre_reddition_detaillee,
    });
  };

  getProprietaires = () => {
    let proprietairesFiltered = [];

    if (this.props.proprietaires) {
      if (this.state.typePaiement === "1") {
        proprietairesFiltered = this.props.proprietaires.filter(
          (item) =>
            item.proprietaire_personne.personne_compte_bancaire.compte_bancaire_type_paiement
              .type_paiement_libelle === "Virement"
        );
      }

      if (this.state.typePaiement === "2") {
        proprietairesFiltered = this.props.proprietaires.filter(
          (item) =>
            item.proprietaire_personne.personne_compte_bancaire.compte_bancaire_type_paiement
              .type_paiement_libelle === "Chèque"
        );
      }

      if (this.state.longueDuree) {
        proprietairesFiltered = proprietairesFiltered.filter(
          (pro) => pro.proprietaire_longue_duree === true
        );
      }
    }

    return proprietairesFiltered;
  };

  delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  preparation = (selectedRows) => {
    this.setState({ preparation: true }, async () => {
      await this.delay(500).then(() => this.getLigneReddition(selectedRows));
    });
  };

  getLigneReddition = (selectedRows) => {
    let ibanMissing = false;
    let lignesRedditionFilteredByRows = [];
    if (selectedRows.length > 0) {
      if (this.props.locations && this.props.quittances && this.props.reglements) {
        selectedRows.forEach((row) => {
          const proprietaire_id = row.proprietaire_id;
          // LOCATIONS
          let locationsFiltered = this.props.locations.filter(
            (location) => location.location_lot.lot_proprietaire.proprietaire_id === proprietaire_id
          );

          // CHECK IF COMMENCEES
          if (this.state.commencees) {
            locationsFiltered = locationsFiltered.filter((location) =>
              dayjs(location.location_date_debut).isSameOrBefore(
                dayjs(this.state.dateButoir, "DD/MM/YYYY")
              )
            );
          }

          // CHECK IF REGLEES
          if (this.state.reglees) {
            const locationsToRemove = [];
            locationsFiltered.forEach((location) => {
              const quittancesFiltered = this.props.quittances.filter(
                (quittance) => quittance.quittance_location_id === location.location_id
              );
              let quittancesLignes = [];
              if (quittancesFiltered.length > 0) {
                quittancesFiltered.forEach((quittance) => {
                  quittance.quittance_ligne.forEach((ligne) => {
                    quittancesLignes.push(ligne);
                  });
                });
              }
              const quittancesFilteredId = quittancesFiltered.map(
                (quittance) => quittance.quittance_id
              );
              const reglementsFiltered = this.props.reglements.filter(
                (reglement) =>
                  quittancesFilteredId.includes(reglement.reglement_quittance.quittance_id) &&
                  // reglement.reglement_reddition === false &&
                  dayjs(reglement.reglement_date).isSameOrBefore(
                    dayjs(this.state.dateButoir, "DD/MM/YYYY")
                  )
              );
              const totalQuittances = quittancesLignes.reduce(
                (a, b) => a + parseFloat(b.quittance_ligne_montant),
                0
              );
              const totalReglements = reglementsFiltered.reduce(
                (a, b) => a + parseFloat(b.reglement_montant),
                0
              );
              if (
                parseFloat(parseFloat(totalQuittances).toFixed(2)) >
                parseFloat(parseFloat(totalReglements).toFixed(2))
              ) {
                locationsToRemove.push(location.location_id);
              }
            });
            locationsFiltered = locationsFiltered.filter(
              (location) => !locationsToRemove.includes(location.location_id)
            );
          }

          const locationsFilteredId = locationsFiltered.map((item) => item.location_id);

          // QUITTANCES
          // CHECK IF COMMENCEES
          let quittancesFiltered = [];
          if (this.state.commencees) {
            quittancesFiltered = this.props.quittances.filter(
              (quittance) =>
                locationsFilteredId.includes(quittance.quittance_location_id) &&
                dayjs(quittance.quittance_date_debut).isSameOrBefore(
                  dayjs(this.state.dateButoir, "DD/MM/YYYY")
                )
            );
          } else {
            quittancesFiltered = this.props.quittances.filter((quittance) =>
              locationsFilteredId.includes(quittance.quittance_location_id)
            );
          }

          let quittancesLignes = [];
          if (quittancesFiltered.length > 0) {
            quittancesFiltered.forEach((quittance) => {
              quittance.quittance_ligne.forEach((ligne) => {
                quittancesLignes.push(ligne);
              });
            });
          }
          const montantQuittances = quittancesLignes.reduce(
            (a, b) => a + parseFloat(b.quittance_ligne_montant),
            0
          );
          const quittancesFilteredId = quittancesFiltered.map(
            (quittance) => quittance.quittance_id
          );

          // ALL REGLEMENTS
          const allReglements = this.props.reglements.filter(
            (reglement) =>
              quittancesFilteredId.includes(reglement.reglement_quittance.quittance_id) &&
              dayjs(reglement.reglement_date).isSameOrBefore(
                dayjs(this.state.dateButoir, "DD/MM/YYYY")
              )
          );

          let montantAllReglements = 0;
          if (allReglements.length > 0) {
            montantAllReglements = allReglements.reduce(
              (a, b) => a + parseFloat(b.reglement_montant),
              0
            );
          }

          // REGLEMENTS
          const reglementsFiltered = this.props.reglements.filter(
            (reglement) =>
              quittancesFilteredId.includes(reglement.reglement_quittance.quittance_id) &&
              reglement.reglement_reddition === false &&
              dayjs(reglement.reglement_date).isSameOrBefore(
                dayjs(this.state.dateButoir, "DD/MM/YYYY")
              )
          );

          // ECRITURES
          const lignesEcritures = [];
          let montantCompta = 0;
          const affectations = row.proprietaire_affectations;
          if (affectations.length > 0) {
            const ecrituresFiltered = this.props.ecritures.filter(
              (ecriture) =>
                (ecriture.ecriture_action === null || ecriture.ecriture_action === "DP") &&
                dayjs(ecriture.ecriture_date_ecriture, "DD/MM/YYYY").isSameOrBefore(
                  dayjs(this.state.dateButoirDepenses, "DD/MM/YYYY")
                )
            );
            ecrituresFiltered &&
              ecrituresFiltered.forEach((ecriture) => {
                const lignes = ecriture.ecriture_ligne.filter(
                  (e) =>
                    e.ecriture_ligne_en_reddition === false &&
                    e.ecriture_ligne_compte.compte_id ===
                      affectations[0].proprietaire_affectation_compte.compte_id
                );
                lignes &&
                  lignes.forEach((ligne) => {
                    if (
                      !ligne.ecriture_ligne_libelle.includes("Regl.") &&
                      !ligne.ecriture_ligne_libelle.includes("Virement solde du") &&
                      !ligne.ecriture_ligne_libelle.includes("Virement acompte du") &&
                      !ligne.ecriture_ligne_libelle.includes("Reddition du") &&
                      !ligne.ecriture_ligne_libelle.includes("Honoraires de gestion")
                    ) {
                      montantCompta += parseFloat(
                        ligne.ecriture_ligne_montant_credit - ligne.ecriture_ligne_montant_debit
                      );
                      ligne.ecriture_id = ecriture.ecriture_id;
                      ligne.ecriture_journal_code = ecriture.ecriture_journal.journal_code;
                      ligne.ecriture_date_ecriture = ecriture.ecriture_date_ecriture;
                      ligne.visible = ecriture.ecriture_action !== "DP";
                      ligne.compta = true;
                      lignesEcritures.push(ligne);
                    }
                  });
              });
            // LIGNE DE DÉPENSES PROPRIÉTAIRES
            const ecrituresDP = this.props.ecritures.filter(
              (ecriture) =>
                ecriture.ecriture_action === "DP" &&
                dayjs(ecriture.ecriture_date_ecriture, "DD/MM/YYYY").isSameOrBefore(
                  dayjs(this.state.dateButoirDepenses, "DD/MM/YYYY")
                )
            );
            const lignesDP = ecrituresDP
              .flatMap((ecriture) => ecriture.ecriture_ligne)
              .filter(
                (e) =>
                  e.ecriture_ligne_en_reddition === false &&
                  e.ecriture_ligne_compte.compte_id ===
                    affectations[0].proprietaire_affectation_compte.compte_id
              );
            const ligneCumulDP = lignesDP.reduce(
              (acc, ligne) => {
                return {
                  ecriture_id: 0,
                  ecriture_journal_code: 0,
                  ecriture_date_ecriture: dayjs().format("DD/MM/YYYY"),
                  ecriture_ligne_id: 0,
                  ecriture_ligne_libelle: `FRAIS À LA CHARGE DU PROPRIÉTAIRE`,
                  visible: true,
                  compta: false,
                  ecriture_ligne_montant_debit:
                    acc.ecriture_ligne_montant_debit +
                    parseFloat(ligne.ecriture_ligne_montant_debit),
                  ecriture_ligne_montant_credit:
                    acc.ecriture_ligne_montant_credit +
                    parseFloat(ligne.ecriture_ligne_montant_credit),
                };
              },
              { ecriture_ligne_montant_debit: 0, ecriture_ligne_montant_credit: 0 }
            );
            lignesEcritures.push(ligneCumulDP);
          }

          const locs = [];
          locs.reglements = [];
          let montantProprietaire = 0;
          let montantFrais = 0;
          reglementsFiltered.forEach((reglement) => {
            const quittance = quittancesFiltered.find(
              (e) => e.quittance_id === reglement.reglement_quittance.quittance_id
            );
            const location = {
              ...locationsFiltered.find((e) => e.location_id === quittance.quittance_location_id),
            };

            let montantReglements = 0;
            let montantReglementsHonoraires = 0;
            if (reglement.reglement_ligne.length > 0) {
              reglement.reglement_ligne.forEach((ligne) => {
                if (
                  ligne.reglement_ligne_quittance_ligne_id.quittance_ligne_rubrique
                    .rubrique_type === "P"
                ) {
                  montantReglements += parseFloat(ligne.reglement_ligne_montant);
                  montantProprietaire += parseFloat(ligne.reglement_ligne_montant);
                  if (
                    ligne.reglement_ligne_quittance_ligne_id.quittance_ligne_rubrique
                      .rubrique_honoraires === true
                  ) {
                    montantReglementsHonoraires += parseFloat(ligne.reglement_ligne_montant);
                  }
                } else if (
                  ligne.reglement_ligne_quittance_ligne_id.quittance_ligne_rubrique
                    .rubrique_type === "G"
                ) {
                  if (
                    ligne.reglement_ligne_quittance_ligne_id.quittance_ligne_rubrique
                      .rubrique_honoraires === true
                  ) {
                    montantReglementsHonoraires += parseFloat(ligne.reglement_ligne_montant);
                  }
                }
              });
            }

            const fraisGestion = this.getFraisGestion(location);

            montantFrais +=
              (parseFloat(fraisGestion) * parseFloat(montantReglementsHonoraires)) / 100;

            location.montant = montantReglements;
            location.dettesLocataires = montantQuittances - montantAllReglements;
            locs.push(location);
          });

          let totalDebit = 0;
          let totalCredit = 0;

          if (affectations.length > 0) {
            this.props.ecritures &&
              this.props.ecritures.forEach((ecriture) => {
                const lignes = ecriture.ecriture_ligne.filter(
                  (e) =>
                    e.ecriture_ligne_compte.compte_id ===
                    affectations[0].proprietaire_affectation_compte.compte_id
                );
                lignes &&
                  lignes.forEach((ligne) => {
                    totalDebit += parseFloat(ligne.ecriture_ligne_montant_debit);
                    totalCredit += parseFloat(ligne.ecriture_ligne_montant_credit);
                  });
              });
          }

          let totalSolde = totalCredit - totalDebit;

          // RESULTS
          const ligneReddition = {
            proprietaire_id: row.proprietaire_id,
            proprietaire: row.proprietaire_personne,
            proprietaire_affectations: row.proprietaire_affectations,
            proprietaire_code: row.proprietaire_code,
            proprietaire_nom: row.proprietaire_personne.personne_nom,
            proprietaire_prenom: row.proprietaire_personne.personne_prenom,
            locations: locs,
            lignesEcritures: lignesEcritures,
            reglements: reglementsFiltered,
            montantProprietaire: parseFloat(montantProprietaire),
            montantTotal: parseFloat(montantProprietaire + montantCompta),
            montantFrais: montantFrais,
            montantHT: parseFloat(parseFloat(montantProprietaire + montantCompta)),
            montantDu: parseFloat(montantProprietaire + montantCompta - montantFrais),
            solde: totalSolde,
            dettesLocataires: locs.reduce((a, b) => a + parseFloat(b.dettesLocataires), 0),
          };

          if (
            row.proprietaire_personne.personne_compte_bancaire.compte_bancaire_type_paiement
              .type_paiement_libelle === "Virement" &&
            !row.proprietaire_personne.personne_compte_bancaire.compte_bancaire_IBAN
          ) {
            // ibanMissing = true;
          }

          if (ligneReddition.montant !== 0) {
            lignesRedditionFilteredByRows.push(ligneReddition);
          }
        });
      }
    }
    if (!ibanMissing) {
      this.setState({
        lignesReddition: lignesRedditionFilteredByRows,
        // selectedRowKeys: [],
        // selectedRows: [],
        showProgress: false,
        selectedRowRedditionKeys: [],
        selectedRowsReddition: [],
        preparation: false,
        loading: false,
      });
    } else {
      this.setState({ preparation: false }, () =>
        toast.error(
          "Informations bancaires incomplètes pour au moins un des propriétaire sélectionné !",
          { containerId: "A" }
        )
      );
    }
  };

  getFraisGestion = (location) => {
    let fraisGestion = 0;
    switch (location.location_type_contrat) {
      case 1:
        fraisGestion = location.location_lot.lot_frais_gestion_alur
          ? location.location_lot.lot_frais_gestion_alur
          : 0;
        break;
      case 2:
        fraisGestion = location.location_lot.lot_frais_gestion_lt
          ? location.location_lot.lot_frais_gestion_lt
          : 0;
        break;
      case 3:
        fraisGestion = location.location_lot.lot_frais_gestion_ct
          ? location.location_lot.lot_frais_gestion_ct
          : 0;
        break;
      default:
        fraisGestion = location.location_lot.lot_frais_gestion_ct
          ? location.location_lot.lot_frais_gestion_ct
          : 0;
        break;
    }
    return fraisGestion;
  };

  onChangeSociete = (id) => {
    this.setState({ societe: id });
  };

  onChangeJournal = (id) => {
    this.setState({ journal: id });
  };

  onChangeTypePaiement = (value) => {
    this.setState({ typePaiement: value });
  };

  onChangeDateButoir = (e) => {
    this.setState({ dateButoir: e, dateButoirDepenses: e }, () => {
      this.formRef.current.setFieldsValue({
        dateDepenses: dayjs(e, "DD/MM/YYYY"),
      });
    });
  };

  onChangeDateButoirDepenses = (e) => {
    this.setState({ dateButoirDepenses: e });
  };

  onChangeDateReleve = (e) => {
    this.setState({ dateReleve: e });
  };

  onChangeCommencees = (value) => {
    this.setState({ commencees: value.target.checked });
  };

  onChangeReglees = (value) => {
    this.setState({ reglees: value.target.checked });
  };

  onChangeDetaillee = (value) => {
    this.setState({ detaillee: value.target.checked });
  };

  onChangeLongueDuree = (value) => {
    this.setState({ longueDuree: value.target.checked });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Recherche`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 190, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          style={{ width: 90 }}
        >
          Rechercher
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Effacer
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined className={filtered ? "primary" : ""} />,
    onFilter: (value, record) => {
      if (dataIndex === "proprietaire_code") {
        return (
          record.proprietaire_code &&
          record.proprietaire_code.toString().toLowerCase().includes(value.toLowerCase())
        );
      } else if (dataIndex === "proprietaire_personne.personne_nom") {
        return (
          record.proprietaire_personne.personne_nom &&
          record.proprietaire_personne.personne_nom
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        );
      } else if (dataIndex === "proprietaire_personne.personne_prenom") {
        return (
          record.proprietaire_personne.personne_prenom &&
          record.proprietaire_personne.personne_prenom
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        );
      } else {
        return (
          record[dataIndex] &&
          record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        );
      }
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
  });

  sortByName(a, b) {
    a = a && a.toUpperCase();
    b = b && b.toUpperCase();
    if (b == null || a < b) {
      return -1;
    }
    if (a == null || a > b) {
      return 1;
    }
    return 0;
  }

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  onSubmitForm = () => {
    this.formRef.current.submit();
  };

  // A factoriser: présent dans FormMailMultiple
  onFinishReddtion = (data) => {
    this.setState({ showProgress: true, loading: true }, () => {
      const journalBanque = this.props.journals.find((item) => item.journal_id === data.journal_id);
      const journalHonoraire = this.props.journals.find((item) => item.journal_code === "70");
      if (journalBanque.journal_compte && journalHonoraire.journal_compte) {
        this.generateCompta(data, journalBanque, journalHonoraire);
      } else {
        toast.error("Le jounal sélectionné n'a pas de compte de contrepartie !", {
          containerId: "A",
        });
        this.setState({ loading: false });
      }
    });
  };

  generateCompta = (data, journalBanque, journalHonoraire) => {
    this.state.selectedRowsReddition.some((ligne) => ligne.montantHT > 0) &&
    data.centralises === true
      ? this.createEcritureBanque(this.state.selectedRowsReddition, journalBanque)
      : this.state.selectedRowsReddition.forEach((selectedRow) => {
          this.createEcritureBanque([selectedRow], journalBanque);
        });
    this.state.selectedRowsReddition.some((ligne) => ligne.montantFrais > 0) &&
      this.createEcritureHonoraires(this.state.selectedRowsReddition, journalHonoraire);
    // Règlements en_reddition
    const reglements = [];
    this.state.selectedRowsReddition.forEach((ligne) => {
      ligne.reglements.forEach((reglement) => {
        reglements.push(reglement);
      });
    });
    this.props.updateReglements({ reglements: reglements }).then(() => {
      // Lignes ecritures en_reddition
      const ecritures = [];
      this.state.selectedRowsReddition.forEach((ligneReddition) => {
        ligneReddition.lignesEcritures
          .filter((ligne) => ligne.compta)
          .forEach((ligne) => {
            const ecriture = this.props.ecritures.find(
              (ecriture) => ligne.ecriture_id === ecriture.ecriture_id
            );
            if (ecriture) {
              ecriture.ecriture_ligne.forEach((ligneEcriture) => {
                if (ligneEcriture.ecriture_ligne_id === ligne.ecriture_ligne_id) {
                  ligneEcriture.ecriture_ligne_en_reddition = true;
                }
              });
              ecritures.push(ecriture);
            }
          });
      });
      this.props.updateEcritures({ ecritures: ecritures }).then(() => {
        this.prepareLigne(0, this.state.selectedRowsReddition.length);
      });
    });
  };

  createEcritureBanque = (selectedRows, journal) => {
    const ecritureLignes = [];
    let montant = 0;
    selectedRows.forEach((row) => {
      if (row.montantHT > 0) {
        const ligneDebit = {
          ecriture_ligne_libelle: `Relevé de gestion du ${
            this.state.dateReleve
              ? dayjs(this.state.dateReleve, "DD/MM/YYYY").format("DD/MM/YYYY")
              : dayjs().format("DD/MM/YYYY")
          }`,
          ecriture_ligne_montant_debit: parseFloat(row.montantHT - row.montantFrais).toFixed(2),
          ecriture_ligne_montant_credit: 0,
          ecriture_ligne_compte_id:
            row.proprietaire_affectations[0].proprietaire_affectation_compte.compte_id,
          ecriture_ligne_locataire_code: "",
          ecriture_ligne_en_reddition: true,
        };
        montant += parseFloat(row.montantHT - row.montantFrais);
        ecritureLignes.push(ligneDebit);
      }
    });
    const ligneCredit = {
      ecriture_ligne_libelle: `Relevé de gestion du ${
        this.state.dateReleve
          ? dayjs(this.state.dateReleve, "DD/MM/YYYY").format("DD/MM/YYYY")
          : dayjs().format("DD/MM/YYYY")
      }`,
      ecriture_ligne_montant_debit: 0,
      ecriture_ligne_montant_credit: parseFloat(montant).toFixed(2),
      ecriture_ligne_compte_id: journal.journal_compte.compte_id,
      ecriture_ligne_locataire_code: "",
      ecriture_ligne_en_reddition: true,
    };
    ecritureLignes.push(ligneCredit);
    const ecriture = {
      ecriture_journal_id: journal.journal_id,
      ecriture_date_ecriture: this.state.dateReleve
        ? dayjs(this.state.dateReleve, "DD/MM/YYYY").format("DD/MM/YYYY")
        : dayjs().format("DD/MM/YYYY"),
      ecriture_libelle: `Relevé de gestion du ${
        this.state.dateReleve
          ? dayjs(this.state.dateReleve, "DD/MM/YYYY").format("DD/MM/YYYY")
          : dayjs().format("DD/MM/YYYY")
      }`,
      ecriture_ligne: ecritureLignes,
    };
    this.props
      .saveEcriture(ecriture)
      .catch((err) => toast.error("Erreur création écriture banque !", { containerId: "A" }));
  };

  createEcritureHonoraires = (selectedRows, journal) => {
    const ecritureLignes = [];
    let montant = 0;
    selectedRows.forEach((row) => {
      if (row.montantFrais !== 0) {
        const ligneDebit = {
          ecriture_ligne_libelle: `Honoraires de gestion TTC sur ${parseFloat(
            row.montantProprietaire
          ).toFixed(2)}€`,
          ecriture_ligne_montant_debit: parseFloat(row.montantFrais).toFixed(2),
          ecriture_ligne_montant_credit: 0,
          ecriture_ligne_compte_id:
            row.proprietaire_affectations[0].proprietaire_affectation_compte.compte_id,
          ecriture_ligne_locataire_code: "",
          ecriture_ligne_en_reddition: true,
        };
        montant += parseFloat(row.montantFrais);
        ecritureLignes.push(ligneDebit);
      }
    });
    const ligneCredit = {
      ecriture_ligne_libelle: `Honoraires de gestion TTC`,
      ecriture_ligne_montant_debit: 0,
      ecriture_ligne_montant_credit: parseFloat(montant).toFixed(2),
      ecriture_ligne_compte_id: journal.journal_compte.compte_id,
      ecriture_ligne_locataire_code: "",
      ecriture_ligne_en_reddition: true,
    };
    ecritureLignes.push(ligneCredit);
    const ecriture = {
      ecriture_journal_id: journal.journal_id,
      ecriture_date_ecriture: this.state.dateReleve
        ? dayjs(this.state.dateReleve, "DD/MM/YYYY").format("DD/MM/YYYY")
        : dayjs().format("DD/MM/YYYY"),
      ecriture_libelle: `Honoraires de gestion TTC`,
      ecriture_ligne: ecritureLignes,
    };
    this.props
      .saveEcriture(ecriture)
      .catch((err) => toast.error("Erreur création écriture honoraires !", { containerId: "A" }));
  };

  prepareLigne = (i, len) => {
    if (i < len) {
      const ligne = this.state.selectedRowsReddition[i];
      if (ligne.proprietaire_affectations.length > 0) {
        const dossier = ligne.proprietaire_affectations[0].proprietaire_affectation_dossier;
        if (dossier !== null) {
          this.setState({ percent: parseInt((i / len) * 100) }, () => {
            this.preparePdf(i, ligne, ligne.proprietaire_affectations[0]);
          });
        } else {
          this.props.saveDossier(this.getJsonDossier(ligne)).then((dossier) => {
            this.props
              .updateProprietaireAffectation(
                {
                  proprietaire_affectation_compte_id:
                    ligne.proprietaire_affectations[0].proprietaire_affectation_compte_id,
                  proprietaire_affectation_dossier_id: dossier ? dossier.dossier_id : null,
                },
                ligne.proprietaire_id,
                ligne.proprietaire_affectations[0].proprietaire_affectation_societe.societe_id
              )
              .then((affectation) => {
                this.setState({ percent: parseInt((i / len) * 100) }, () => {
                  this.preparePdf(i, ligne, affectation);
                });
              });
          });
        }
      }
    } else {
      toast.success("Relevé de gestion validé et comptabilisé !", { containerId: "A" });
      this.setState({
        loading: false,
        percent: 100,
        lignesReddition: [],
        modalMail: !this.state.validation,
      });
    }
  };

  // A factoriser: présent dans FormMailMultiple
  getJsonDossier = (proprietaire) => {
    return {
      dossier_url: `Proprietaires/${proprietaire.proprietaire_id}/`,
    };
  };

  preparePdf = (i, ligne, affectation) => {
    const html = ReactDOMServer.renderToStaticMarkup(
      <RelevePDF
        utilisateur={this.props.utilisateur}
        dateButoir={this.state.dateButoir}
        detaillee={this.state.detaillee}
        ligneReddition={ligne}
      />
    );
    const pdf = {
      titre: "Releve",
      destinataire: "",
      pdf_page: [],
    };
    const pdfPage = {
      html: html,
    };
    pdf.pdf_page.push(pdfPage);
    const { url, params } = initFetch(`pdfs`, HttpMethod.POST);
    fetch(url, { ...params, body: JSON.stringify(pdf) })
      .then(function (response) {
        return response.json();
      })
      .then((result) => {
        this.getFileFromUrl(i, result.pdf.id, affectation.proprietaire_affectation_dossier);
      });
  };

  getFileFromUrl = (i, id, dossier) => {
    const { url, params } = initFetch(
      `pdf/document_b.php?num=${id}&name=document`,
      HttpMethod.GET,
      {
        Authorization: true,
        formData: false,
        pdf: true,
      }
    );
    fetch(url, params)
      .then((e) => {
        return e.blob();
      })
      .then((blob) => {
        let b = blob;
        b.lastModifiedDate = new Date();
        b.name = `Releve_de_Gestion_du_${dayjs().format("DD-MM-YYYY")}_Num-${id}.pdf`;

        if (this.props.tags.length > 0) {
          this.props.saveDocument({ file: b }, dossier, this.props.tags[0]).then(() => {
            this.prepareLigne(i + 1, this.state.selectedRowsReddition.length);
          });
        }
      });
  };

  createPdfListe = (lignesReddition) => {
    const html = ReactDOMServer.renderToStaticMarkup(
      <ListeRedditionPDF
        utilisateur={this.props.utilisateur}
        dateButoir={this.state.dateButoir}
        lignesReddition={lignesReddition}
      />
    );
    const pdf = {
      titre: "Liste préparatoire",
      destinataire: "",
      pdf_page: [],
    };
    const pdfPage = {
      html: html,
    };
    pdf.pdf_page.push(pdfPage);
    const { url, params } = initFetch(`pdfs`, HttpMethod.POST);
    fetch(url, { ...params, body: JSON.stringify(pdf) })
      .then(function (response) {
        return response.json();
      })
      .then(
        function (result) {
          this.setState({ idPdf: result.pdf.id, modalListe: true });
        }.bind(this)
      );
  };

  createPdf = (lignesReddition) => {
    const htmls = [];
    lignesReddition.forEach((ligne) => {
      const html = ReactDOMServer.renderToStaticMarkup(
        <RelevePDF
          utilisateur={this.props.utilisateur}
          dateButoir={this.state.dateButoir}
          detaillee={this.state.detaillee}
          tva={this.props.parametre.parametre_reddition_tva}
          ligneReddition={ligne}
        />
      );
      htmls.push(html);
    });
    const societe = this.props.utilisateur.utilisateur_etablissement.etablissement_societe;
    const pdf = {
      titre: "Releve",
      destinataire: `${societe.societe_zone_libre_1 ? societe.societe_zone_libre_1 + "<br/>" : ""}${
        societe.societe_zone_libre_2 ? societe.societe_zone_libre_2 + "<br/>" : ""
      }${societe.societe_zone_libre_3 ? societe.societe_zone_libre_3 + "<br/>" : ""}${
        societe.societe_zone_libre_4 ? societe.societe_zone_libre_4 + "<br/>" : ""
      }${societe.societe_zone_libre_5 ? societe.societe_zone_libre_5 + "<br/>" : ""}`,
      pdf_page: [],
    };
    htmls.forEach((html) => {
      const pdfPage = {
        html: html,
      };
      pdf.pdf_page.push(pdfPage);
    });
    const { url, params } = initFetch(`pdfs`, HttpMethod.POST);
    fetch(url, { ...params, body: JSON.stringify(pdf) })
      .then(function (response) {
        return response.json();
      })
      .then(
        function (result) {
          this.setState({ idPdf: result.pdf.id, modalPdf: true });
        }.bind(this)
      );
  };

  getSepa = (lignesReddition) => {
    const journal = this.props.journals.find((item) => item.journal_id === this.state.journal);
    this.getSepaGroupe(journal, lignesReddition);
  };

  getSepaGroupe = (journal, lignesReddition) => {
    const SEPA = require("sepa");

    const doc = new SEPA.Document("pain.001.001.03");
    doc._xmlEncoding = "UTF-8";
    doc.grpHdr.id = "01";
    doc.grpHdr.created = new Date();
    doc.grpHdr.initiatorName = this.formatTxtForXML(
      journal.journal_compte_bancaire.compte_bancaire_titulaire
    );

    const info = doc.createPaymentInfo();
    info.requestedExecutionDate = new Date();
    info.debtorIBAN = journal.journal_compte_bancaire.compte_bancaire_IBAN.split(" ").join("");
    info.debtorBIC = journal.journal_compte_bancaire.compte_bancaire_BIC;
    info.debtorName = this.formatTxtForXML(
      journal.journal_compte_bancaire.compte_bancaire_titulaire
    );
    info.debtorId = "01";
    doc.addPaymentInfo(info);

    lignesReddition.forEach((ligne) => {
      const tx = info.createTransaction();
      tx.creditorName = this.formatTxtForXML(ligne.proprietaire_nom);
      tx.creditorIBAN = ligne.proprietaire.personne_compte_bancaire.compte_bancaire_IBAN
        .split(" ")
        .join("");
      tx.creditorBIC = ligne.proprietaire.personne_compte_bancaire.compte_bancaire_BIC;
      tx.mandateId = `${ligne.proprietaire_id}`;
      tx.mandateSignatureDate = new Date();
      tx.amount = round(ligne.montantDu, 2);
      tx.remittanceInfo = `${this.formatTxtForXML(
        this.props.utilisateur.utilisateur_etablissement.etablissement_societe
          .societe_raison_sociale
      )} Virt Releve de gestion du ${dayjs().format("DD/MM/YYYY")}`;
      tx.end2endId = this.formatTxtForXML(
        ligne.proprietaire.personne_compte_bancaire.compte_bancaire_titulaire
      );
      info.addTransaction(tx);
    });

    let sepa = doc.toString();
    sepa = sepa.replace('encoding="null"', 'encoding="UTF-8"');
    const name = `sepa-du-${dayjs().format("DD/MM/YYYY-HH:mm")}.xml`;

    var element = document.createElement("a");
    var file = new Blob([sepa], { type: "text/xml;charset=utf-8" });
    element.href = URL.createObjectURL(file);
    element.download = name;

    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  formatTxtForXML = (txt) => {
    // Limiter la longueur à 35 caractères
    if (txt.length > 35) {
      txt = txt.substring(0, 35);
    }

    // Remplacer les @ par des A
    txt = txt.replace(/@/g, "A");

    // Fonction pour supprimer les accents
    function removeAccents(str) {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }

    // Supprimer les accents
    txt = removeAccents(txt);

    return txt;
  };

  prepareMailing = () => {
    this.setState({ modalMail: true, validation: true });
  };

  closeModalListe = () => {
    this.setState({ modalListe: false });
  };

  closeModalPdf = () => {
    this.setState({ modalPdf: false });
  };

  closeModalMail = () => {
    this.setState({ modalMail: false });
  };

  onClickAfficher = (key, title, proprietaire) => {
    // const pane = { key, title, content: "" };
    this.props.addPanesPersonne(proprietaire, true, true);
  };

  setBackgroundColor = (item) => {
    if (round(item.solde, 2) < round(item.montantHT, 2) || round(item.solde, 2) < 0) {
      return "editable-row row-edited-bg";
    } else {
      return "editable-row";
    }
  };

  checkDebit = (lignesReddition) => {
    return lignesReddition.some((ligne) => ligne.montantHT <= 0);
  };

  handleAnnulation = () => {
    this.setState({
      lignesReddition: [],
      selectedRowKeys: [],
      selectedRows: [],
      selectedRowRedditionKeys: [],
      selectedRowsReddition: [],
    });
  };

  render() {
    const {
      preparation,
      loading,
      showProgress,
      percent,
      societe,
      journal,
      typePaiement,
      dateButoir,
      dateButoirDepenses,
      detaillee,
      dateReleve,
      lignesReddition,
      selectedRowKeys,
      selectedRows,
      selectedRowRedditionKeys,
      selectedRowsReddition,
      modalListe,
      modalPdf,
      modalMail,
      idPdf,
    } = this.state;
    const { societes, journals } = this.props;

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({ selectedRowKeys, selectedRows });
      },
      selectedRowKeys: selectedRowKeys,
    };

    const rowRedditionSelection = {
      onChange: (selectedRowRedditionKeys, selectedRowsReddition) => {
        this.setState({ selectedRowRedditionKeys, selectedRowsReddition });
      },
      getCheckboxProps: (record) => ({
        disabled: record.montantHT === 0,
      }),
      selectedRowKeys: selectedRowRedditionKeys,
    };

    const proprietairesColumns = [
      {
        title: "Code",
        sorter: (a, b) => parseInt(a.proprietaire_code) - parseInt(b.proprietaire_code),
        ...this.getColumnSearchProps("proprietaire_code"),
        render: (record) => record.proprietaire_code,
      },
      {
        title: "Nom",
        defaultSortOrder: "ascend",
        sorter: (a, b) =>
          this.sortByName(
            a.proprietaire_personne.personne_nom,
            b.proprietaire_personne.personne_nom
          ),
        ...this.getColumnSearchProps("proprietaire_personne.personne_nom"),
        render: (record) => record.proprietaire_personne.personne_nom,
      },
      {
        title: "Prénom",
        sorter: (a, b) =>
          this.sortByName(
            a.proprietaire_personne.personne_prenom,
            b.proprietaire_personne.personne_prenom
          ),
        ...this.getColumnSearchProps("proprietaire_personne.personne_prenom"),
        render: (record) => record.proprietaire_personne.personne_prenom,
      },
      {
        title: "IBAN",
        render: (record) =>
          record.proprietaire_personne.personne_compte_bancaire.compte_bancaire_type_paiement
            .type_paiement_id === "Virement" &&
          !record.proprietaire_personne.personne_compte_bancaire.compte_bancaire_IBAN ? (
            <Tag color="error">!</Tag>
          ) : (
            <></>
          ),
        width: "20%",
      },
      {
        title: "Afficher",
        render: (proprietaire) => (
          <Button onClick={() => this.onClickAfficher("17", "Proprietaires", proprietaire)}>
            <EyeOutlined />
          </Button>
        ),
        width: 100,
      },
    ];

    const lignesRedditionColumns = [
      {
        title: "Code",
        dataIndex: "proprietaire_code",
        sorter: (a, b) => parseInt(a.proprietaire_code) - parseInt(b.proprietaire_code),
        width: "10%",
      },
      {
        title: "Nom",
        dataIndex: "proprietaire_nom",
        defaultSortOrder: "ascend",
        sorter: (a, b) => a.proprietaire_nom.localeCompare(b.proprietaire_nom),
        width: "15%",
      },
      {
        title: "Prénom",
        dataIndex: "proprietaire_prenom",
        width: "15%",
      },
      {
        title: "Montant hors honoraires",
        dataIndex: "montantHT",
        render: (montantHT) => (
          <Text style={{ float: "right" }}>
            {parseFloat(montantHT).toLocaleString("fr-FR", {
              style: "currency",
              currency: "EUR",
            })}
          </Text>
        ),
        width: "12%",
      },
      {
        title: "Montant honoraires",
        dataIndex: "montantFrais",
        render: (montantFrais) => (
          <Text style={{ float: "right" }}>
            {parseFloat(montantFrais).toLocaleString("fr-FR", {
              style: "currency",
              currency: "EUR",
            })}
          </Text>
        ),
        width: "12%",
      },
      {
        title: "Montant dû",
        dataIndex: "montantDu",
        render: (montantDu) => (
          <Text style={{ float: "right" }}>
            {parseFloat(montantDu).toLocaleString("fr-FR", {
              style: "currency",
              currency: "EUR",
            })}
          </Text>
        ),
        width: "12%",
      },
      {
        title: `Dettes locataires`,
        dataIndex: "dettesLocataires",
        render: (dettesLocataires) => (
          <Text style={{ float: "right" }}>
            {parseFloat(dettesLocataires).toLocaleString("fr-FR", {
              style: "currency",
              currency: "EUR",
            })}
          </Text>
        ),
        width: "12%",
      },
      {
        title: "Solde à ce jour",
        dataIndex: "solde",
        render: (solde) => (
          <Text style={{ float: "right" }}>
            {parseFloat(solde).toLocaleString("fr-FR", {
              style: "currency",
              currency: "EUR",
            })}
          </Text>
        ),
        width: "12%",
      },
    ];

    return (
      <Form
        ref={this.formRef}
        onFinish={this.onFinishReddtion}
        initialValues={{ type_paiement: typePaiement }}
        style={{ padding: "20px" }}
      >
        <Modal
          footer={null}
          title="Liste préparatoire"
          open={modalListe}
          onCancel={this.closeModalListe}
        >
          {idPdf && (
            <Button type="text" onClick={() => openPdf(idPdf)}>
              Liste préparatoire
            </Button>
          )}
        </Modal>

        <Modal footer={null} title="Relevé" open={modalPdf} onCancel={this.closeModalPdf}>
          {idPdf && (
            <Button type="text" onClick={() => openPdf(idPdf, "b")}>
              Relevé de Gérance
            </Button>
          )}
        </Modal>

        <Modal
          width="800px"
          footer={null}
          title="Mailing"
          open={modalMail}
          onCancel={this.closeModalMail}
        >
          <FormMailMultiple
            utilisateur={this.props.utilisateur}
            dateButoir={dateButoir}
            detaillee={detaillee}
            parametre={this.props.parametre}
            tags={this.props.tags}
            lignesReddition={selectedRowsReddition}
            saveEmail={this.props.saveEmail}
            saveDossier={this.props.saveDossier}
            saveDocument={this.props.saveDocument}
            updateProprietaireAffectation={this.props.updateProprietaireAffectation}
          />
        </Modal>

        <Row gutter={[12, 0]}>
          <Col span={12}>
            <Row style={{ marginTop: 30 }}></Row>
            <Row gutter={10}>
              <Col span={12}>
                <CustomSelect
                  label="Société"
                  inputName="societe"
                  formItemName="societe_id"
                  objectValue={societe}
                  onChange={(id) => this.onChangeSociete(id)}
                >
                  <OptGroup label="Société">
                    {societes &&
                      societes.map((item, i) => {
                        return (
                          <Option
                            key={i}
                            value={item.societe_id}
                            label={item.societe_raison_sociale}
                          >
                            <Row>
                              <Col sm={8}>{item.societe_raison_sociale}</Col>
                            </Row>
                          </Option>
                        );
                      })}
                  </OptGroup>
                </CustomSelect>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={12}>
                <CustomSelect
                  label="Type de paiement"
                  inputName="type_paiement"
                  formItemName="type_paiement"
                  objectValue={typePaiement}
                  onChange={(value) => this.onChangeTypePaiement(value)}
                >
                  <OptGroup label="Type">
                    <Option label="Virement" value="1">
                      Virement
                    </Option>
                    <Option label="Chèque" value="2">
                      Chèque
                    </Option>
                  </OptGroup>
                </CustomSelect>
              </Col>
              <Col span={12}>
                <CustomSelect
                  label="Journal"
                  inputName="journal_id"
                  formItemName="journal_id"
                  objectValue={journal}
                  onChange={(id) => this.onChangeJournal(id)}
                >
                  <OptGroup label="Journal">
                    {journals &&
                      journals.map((item, i) => {
                        return (
                          <Option
                            key={i}
                            value={item.journal_id}
                            label={item.journal_code + " - " + item.journal_libelle}
                          >
                            <Row>
                              <Col sm={8}>
                                {item.journal_code} - {item.journal_libelle}
                              </Col>
                            </Row>
                          </Option>
                        );
                      })}
                  </OptGroup>
                </CustomSelect>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={12}>
                <CustomDatePicker
                  label="Date butoir encaissement"
                  inputName="date"
                  formItemName="date"
                  onChange={(e) => this.onChangeDateButoir(e)}
                  objectValue={dateButoir}
                  today={true}
                />
              </Col>
              <Col span={12}>
                <CustomDatePicker
                  label="Date butoir dépenses"
                  inputName="dateDepenses"
                  formItemName="dateDepenses"
                  onChange={(e) => this.onChangeDateButoirDepenses(e)}
                  objectValue={dateButoirDepenses}
                  today={true}
                />
              </Col>
            </Row>
            <Form.Item name="filtres" initialValue={["commencees", "reglees"]}>
              <Checkbox.Group style={{ width: "100%" }}>
                <Checkbox value="commencees" onChange={(value) => this.onChangeCommencees(value)}>
                  {`Périodes commencées avant le ${dayjs(dateButoir, "DD/MM/YYYY").format(
                    "DD/MM/YYYY"
                  )} `}
                </Checkbox>
                <Checkbox value="reglees" onChange={(value) => this.onChangeReglees(value)}>
                  {`Périodes reglées en totalité avant le ${dayjs(dateButoir, "DD/MM/YYYY").format(
                    "DD/MM/YYYY"
                  )} `}
                </Checkbox>
              </Checkbox.Group>
            </Form.Item>
            <Form.Item name="centralises" valuePropName="checked">
              <Checkbox>Règlements centralisés</Checkbox>
            </Form.Item>
            <Form.Item name="detaillee" valuePropName="checked">
              <Checkbox onChange={(value) => this.onChangeDetaillee(value)}>
                Rapport de gestion détaillé
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Row style={{ marginBottom: -10 }}>
              <Col span={6}>
                <div style={{ marginTop: 5 }}>Sélection des propriétaires:</div>
              </Col>
              <Col span={8}>
                <Form.Item name="longueDuree" valuePropName="checked">
                  <Checkbox onChange={(value) => this.onChangeLongueDuree(value)}>
                    Longue durée uniquement
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Table
              columns={proprietairesColumns}
              dataSource={this.getProprietaires()}
              pagination={false}
              rowKey="proprietaire_id"
              rowSelection={{
                type: "checkbox",
                ...rowSelection,
              }}
              scroll={{ y: 252 }}
              size="small"
              showSorterTooltip={false}
            />
            <Button
              icon={<SyncOutlined />}
              loading={preparation}
              style={{ marginTop: 10 }}
              onClick={() => this.preparation(selectedRows)}
            >
              Préparation
            </Button>
          </Col>
        </Row>

        <Row gutter={12} style={{ marginTop: "10px" }}>
          <Col span={21}>
            <Table
              columns={lignesRedditionColumns}
              dataSource={lignesReddition}
              pagination={false}
              rowKey="proprietaire_id"
              rowSelection={{
                type: "checkbox",
                ...rowRedditionSelection,
              }}
              rowClassName={(record) => this.setBackgroundColor(record)}
              scroll={{ y: 252 }}
              showSorterTooltip={false}
              size="small"
              summary={() => {
                let totalMontantHT = 0;
                let totalMontantFrais = 0;
                let totalMontantDu = 0;

                selectedRowsReddition.forEach(({ montantHT, montantFrais, montantDu }) => {
                  totalMontantHT += parseFloat(montantHT);
                  totalMontantFrais += parseFloat(montantFrais);
                  totalMontantDu += parseFloat(montantDu);
                });

                return (
                  <Table.Summary fixed>
                    <Table.Summary.Row>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <strong>TOTAL :</strong>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <Text style={{ float: "right" }}>
                          <strong>
                            {parseFloat(totalMontantHT).toLocaleString("fr-FR", {
                              style: "currency",
                              currency: "EUR",
                            })}
                          </strong>
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <Text style={{ float: "right" }}>
                          <strong>
                            {parseFloat(totalMontantFrais).toLocaleString("fr-FR", {
                              style: "currency",
                              currency: "EUR",
                            })}
                          </strong>
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <Text style={{ float: "right" }}>
                          <strong>
                            {parseFloat(totalMontantDu).toLocaleString("fr-FR", {
                              style: "currency",
                              currency: "EUR",
                            })}
                          </strong>
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                );
              }}
            />
          </Col>
          {lignesReddition.length > 0 && (
            <Col span={3}>
              <Row style={{ marginBottom: 10 }}>
                <Button type="primary" onClick={() => this.createPdfListe(selectedRowsReddition)}>
                  Récap. sélection prop.
                </Button>
              </Row>
              {typePaiement === "1" && (
                <Row style={{ marginBottom: 10 }}>
                  <Button type="primary" onClick={() => this.getSepa(selectedRowsReddition)}>
                    SEPA
                  </Button>
                </Row>
              )}
              <Row style={{ marginBottom: 10 }}>
                <Button type="primary" onClick={() => this.createPdf(selectedRowsReddition)}>
                  Générer PDF
                </Button>
              </Row>
              <Row style={{ marginBottom: 10 }}>
                <Button type="primary" onClick={() => this.prepareMailing()}>
                  Envoyer par mail
                </Button>
              </Row>
            </Col>
          )}
        </Row>
        <Row>{showProgress && <Progress percent={percent} />}</Row>
        {lignesReddition.length > 0 && (
          <Row style={{ marginTop: 10 }}>
            <Button type="primary" size="large" onClick={() => this.handleAnnulation()} danger>
              Annulation
            </Button>
            <Popconfirm
              title="Êtes-vous sûr de vouloir lancer la validation des relevés de gestion ? Attention, après validation l'impression des rapports ne sera plus possible"
              onConfirm={() => this.onSubmitForm()}
            >
              <Button
                type="primary"
                size="large"
                loading={loading}
                disabled={this.checkDebit(selectedRowsReddition)}
                style={{ marginLeft: 10, marginRight: 10 }}
              >
                Validation
              </Button>
            </Popconfirm>
            <CustomDatePicker
              label="Date de relevé"
              inputName="date_releve"
              formItemName="date_releve"
              onChange={(e) => this.onChangeDateReleve(e)}
              objectValue={dateReleve}
              today={true}
            />
            {this.checkDebit(selectedRowsReddition) && (
              <Tooltip title="Au moins un des relevés est débiteur, validation impossible">
                <Button
                  type="primary"
                  shape="circle"
                  style={{ marginLeft: 10 }}
                  icon={<QuestionOutlined />}
                />
              </Tooltip>
            )}
          </Row>
        )}
      </Form>
    );
  }
}

export default FormReddition;

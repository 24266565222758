import React, { useEffect, useState } from "react";

import FloatLabel from "../../FloatLabel";
import { DatePicker, Form } from "antd";

import dayjs from "dayjs";

const dateFormat = "DD/MM/YYYY";

const CustomDatePicker = (props) => {
  const [value, setValue] = useState(false);
  const { label, formItemKey, formItemName, inputName, objectValue, rules, today } = props;

  useEffect(() => {
    objectValue ? setValue(objectValue) : setValue(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.objectValue]);

  const onChange = (e) => {
    props.onChange && props.onChange(e);
    setValue(e);
  };

  return (
    <FloatLabel label={label} name={inputName} value={value}>
      <Form.Item
        key={formItemKey}
        name={formItemName}
        initialValue={today ? dayjs() : null}
        rules={[rules]}
      >
        <DatePicker
          onChange={(e) => onChange(e ? e.hour("12").format("DD/MM/YYYY") : null)}
          format={dateFormat}
          placeholder=""
        />
      </Form.Item>
    </FloatLabel>
  );
};

export default CustomDatePicker;

// DEPENDENCY
import React, { Component } from "react";
import { Card, Checkbox, Col, Form, Input } from "antd";

// COMPONENTS
import { CONTACT } from "../../utils/constants";
import CustomEditor from "../CustomEditor";
import CustomDatePicker from "../CustomFormItem/DatePicker";

export default class CardElement extends Component {
  getEditorState = (id) => {
    const elem = this.props.typeBlocTexte.find(
      (item) => item.lot_champ_perso_champ_perso.champ_perso_id === id
    );
    return elem ? elem.lot_champ_perso_valeur : "";
  };

  getDate = (id) => {
    const elem = this.props.typeDate.find(
      (item) => item.lot_champ_perso_champ_perso.champ_perso_id === id
    );
    return elem ? elem.lot_champ_perso_valeur : "";
  };

  render() {
    const { groupe, isArchive } = this.props;

    const TYPES = CONTACT.CUSTOM.FIELD.TYPE;

    return (
      <Col span={6}>
        <Card title={<h5>{groupe.champ_perso_groupe_libelle}</h5>} bordered={false}>
          {groupe.champ_perso &&
            groupe.champ_perso
              .filter((item) => item.champ_perso_visible === true)
              .sort((a, b) => a.champ_perso_ordre - b.champ_perso_ordre)
              .map((field) => {
                const key = field.champ_perso_id;
                // const key = `element_${field.champ_perso_id}`;
                switch (field.champ_perso_type) {
                  case TYPES[0].value:
                    return (
                      <Form.Item colon={false} key={key} name={key} valuePropName="checked">
                        <Checkbox disabled={isArchive}>{field.champ_perso_libelle}</Checkbox>
                      </Form.Item>
                    );
                  case TYPES[1].value:
                    return (
                      <Form.Item colon={false} key={key} name={key}>
                        <Input
                          disabled={isArchive}
                          addonBefore={field.champ_perso_libelle}
                          type="number"
                        />
                      </Form.Item>
                    );
                  case TYPES[2].value:
                    return (
                      <Form.Item colon={false} key={key} name={key}>
                        <Input
                          disabled={isArchive}
                          addonBefore={field.champ_perso_libelle}
                          maxLength="250"
                        />
                      </Form.Item>
                    );
                  case TYPES[3].value:
                    return (
                      <div key={key}>
                        <CustomEditor
                          formItemKey={key}
                          formItemLabel={field.champ_perso_libelle}
                          objectValue={this.getEditorState(key)}
                        />
                      </div>
                    );
                  case TYPES[4].value:
                    return (
                      <div key={key}>
                        <CustomDatePicker
                          label={field.champ_perso_libelle}
                          formItemKey={key}
                          inputName={key}
                          formItemName={key}
                          objectValue={this.getDate(key)}
                        />
                      </div>
                    );
                  default:
                    return <div>{field.champ_perso_libelle}</div>;
                }
              })}
        </Card>
      </Col>
    );
  }
}

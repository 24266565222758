import React, { Component } from "react";

import Constants from "../../utils/constants";
import FormMailFields from "../FormMail/fields";

import draftToHtml from "draftjs-to-html";
import { EditorState, convertToRaw, ContentState, Modifier } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { toast } from "react-toastify";

import { Row, Col, Form, Card, Table, Spin, Modal, Popconfirm, Button } from "antd";
import htmlToDraft from "html-to-draftjs";
import { DeleteOutlined } from "@ant-design/icons";

const { Column, ColumnGroup } = Table;

class FormMail extends Component {
  state = {
    lot: null,
    email: null,
    requete: null,
    dataEmails: [],
    piecesJointes: [],
    loading: false,
    modalEmailVisible: false,
    email1: "",
    email2: "",
    destinataire: "",
    editorState: null,
  };

  formRef = React.createRef();

  componentDidMount() {
    this.init(this.props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.init(props);
  }

  init = (props) => {
    this.reset();
    this.setDataEmails(props);

    if (props.requete) {
      const requete = props.requetes.find((item) => item.requete_id === props.requete.requete_id);
      this.setEmailFields(requete);
    }

    // Envoyer mail depuis onglet "Pdf" / "Documents"
    if (props.document) {
      const document = props.document;
      document.uid = document.document_id;
      document.name = document.document_nom;
      document.status = "done";
      document.ref = `${document.document_dossier.dossier_url}${document.document_tag.tag_libelle}/`;
      document.url = `${Constants.directory}documents/${document.document_dossier.dossier_url}/${document.document_tag.tag_libelle}/${document.document_nom}`;
      this.setState({
        piecesJointes:
          this.state.piecesJointes.indexOf(document) === -1
            ? [...this.state.piecesJointes, document]
            : this.state.piecesJointes,
      });
    }

    this.setState({
      requete: props.requete,
      lot: props.lot,
      editorState: this.state.editorState
        ? this.state.editorState
        : props.parametre.parametre_signature
        ? EditorState.createWithContent(
            ContentState.createFromBlockArray(
              htmlToDraft(props.parametre.parametre_signature, (nodeName, node) => {
                if (nodeName === "img" && node instanceof HTMLImageElement) {
                  if (node.parentElement instanceof HTMLDivElement) {
                    // for image with div element as a parent
                    const entityConfig = {};
                    const parentElement = node.parentElement;
                    if (parentElement.style.float) {
                      // check float style property
                      entityConfig.alignment = parentElement.style.float;
                    } else if (parentElement.style.textAlign) {
                      // check float style property
                      entityConfig.alignment = parentElement.style.textAlign;
                    }

                    entityConfig.src = node.getAttribute
                      ? node.getAttribute("src") || node.src
                      : node.src;
                    entityConfig.alt = node.alt;
                    entityConfig.height = node.style.height;
                    entityConfig.width = node.style.width;
                    return {
                      type: "IMAGE",
                      mutability: "MUTABLE",
                      data: entityConfig,
                    };
                  }
                }
              })
            )
          )
        : EditorState.createEmpty(),
    });
  };

  reset = () => {
    this.formRef.current?.resetFields(["email_destinataire", "email_copie", "email_objet"]);
  };

  setEmailFields = (requete) => {
    if (requete && requete.requete_locataire) {
      this.setState({
        destinataire:
          requete.requete_locataire.locataire_personne.personne_email1 &&
          requete.requete_locataire.locataire_personne.personne_email1 !== "" &&
          requete.requete_locataire.locataire_personne.personne_email1 + ";",
        email1: requete.requete_locataire.locataire_personne.personne_email1,
        email2: requete.requete_locataire.locataire_personne.personne_email2,
      });
      this.formRef.current.setFieldsValue({
        locataire_email_1: requete.requete_locataire.locataire_personne.personne_email1,
        locataire_email_2: requete.requete_locataire.locataire_personne.personne_email2,
        email_destinataire:
          requete.requete_locataire.locataire_personne.personne_email1 &&
          requete.requete_locataire.locataire_personne.personne_email1 !== "" &&
          requete.requete_locataire.locataire_personne.personne_email1 + ";",
        locataire_checkbox_1:
          requete.requete_locataire.locataire_personne.personne_email1 &&
          requete.requete_locataire.locataire_personne.personne_email1 !== "",
      });
    }
  };

  setDataEmails = (props) => {
    let dataEmails = [];

    if (props.requete) {
      props.requete.requete_emails &&
        props.requete.requete_emails.forEach((item) => {
          if (props.requete.requete_id === item.email_requete_id) {
            const email = {
              key: item.email_id,
              objet: item.email_objet,
              date: item.created_at,
              emetteur: item.email_utilisateur,
              message: item.email_message,
            };

            dataEmails.push(email);
          }
        });
    } else {
      props.lot &&
        props.lot.lot_emails?.forEach((item) => {
          if (props.lot.lot_id === item.email_lot_id) {
            const email = {
              key: item.email_id,
              objet: item.email_objet,
              date: item.created_at,
              emetteur: item.email_utilisateur,
              message: item.email_message,
            };

            dataEmails.push(email);
          }
        });
    }

    this.setState({ dataEmails: dataEmails.sort((a, b) => b.key - a.key) });
  };

  handleCreate = (data) => {
    const arrayDestinataire = [];
    const arrayCopie = [];

    // conversion en HTML de ce qui est écrit dans l'éditeur
    const message = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));

    // La méthode split() utilisée ensuite retourne une ligne vide si l'input se termine par un ";"
    // Vérification puis suppression de ce ";" si nécéssaire
    let email_destinataire = data.email_destinataire;
    if (email_destinataire.endsWith(";")) {
      email_destinataire = email_destinataire.substring(0, email_destinataire.lastIndexOf(";"));
    }

    // récupération de l'input et création d'un tableau avec les emails
    // le système de mail de Laravel aura beosin d'un tableau avec les attributs email/name
    const destinataire = email_destinataire.split(";");
    destinataire.forEach((element) => {
      arrayDestinataire.push({
        email: element,
        name: element,
      });
    });

    // La méthode split() utilisée ensuite retourne une ligne vide si l'input se termine par un ";"
    // Vérification puis suppression de ce ";" si nécéssaire
    let email_copie = data.email_copie;
    if (email_copie) {
      if (email_copie.endsWith(";")) {
        email_copie = email_copie.substring(0, email_copie.lastIndexOf(";"));
      }

      // récupération de l'input et création d'un tableau avec les emails
      // le système de mail de Laravel aura beosin d'un tableau avec les attributs email/name
      const copie = email_copie.split(";");
      copie.forEach((element) => {
        arrayCopie.push({
          email: element,
          name: element,
        });
      });
    }

    let email_pieces_jointes = [];
    this.state.piecesJointes.forEach((item) => {
      const jsonDataPJ = {
        nom: item.name,
        // url: `Requetes/${this.state.requete.requete_id}/`,
        ref: item.ref ? item.ref : "Pieces_jointes/",
        url: item.url ? item.url : `${Constants.directory}documents/Pieces_jointes/${item.name}`,
      };
      item.status !== "removed" && email_pieces_jointes.push(jsonDataPJ);
    });

    // email_destinataire et email_copie seront insérés en base
    // email_destinataire_array et email_copie_array seront utilisés pour les envois
    const jsonDataEmail = {
      email_objet: data.email_objet,
      email_message: message,
      email_destinataire: email_destinataire,
      email_destinataire_array: arrayDestinataire,
      email_copie: email_copie,
      email_copie_array: arrayCopie,
      email_pieces_jointes: email_pieces_jointes,
      email_utilisateur_id: this.props.utilisateur.utilisateur_id,
      email_requete_id: this.state.requete.requete_id,
      email_template_id: data.email_template,
      parametre_smtp: this.props.parametre.parametre_smtp,
      parametre_port: this.props.parametre.parametre_port,
      parametre_email: this.props.parametre.parametre_email,
      parametre_mdp: this.props.parametre.parametre_mdp,
      parametre_copie_agence: this.props.parametre.parametre_copie_agence,
      parametre_email_copie: this.props.parametre.parametre_email_copie,
    };

    toast.info("Envoi en cours...", {
      containerId: "A",
    });

    // Envoi du mail puis sauvegarde de celui-ci
    this.props
      .saveEmail(jsonDataEmail)
      .then((res) => {
        this.setState({ piecesJointes: [] });
        toast.success("Email envoyé !", {
          containerId: "A",
        });
      })
      .catch((err) => {
        toast.error("Échec de l'envoi !", {
          containerId: "A",
        });
      });
  };

  onEditorStateChange = (editorState) => {
    this.setState({ editorState });
  };

  handleChange = (checked, value) => {
    if (checked) {
      const destinataire = this.state.destinataire;
      this.setState({ destinataire: destinataire + value + ";" });
      return this.setDestinataire(destinataire + value + ";");
    } else {
      this.formRef.current.setFieldsValue({
        email_destinataire: "",
      });
      const destinataire = this.state.destinataire.replace(value + ";", "");
      this.setState({ destinataire });
      return this.setDestinataire(destinataire);
    }
  };

  setDestinataire = (value) => {
    this.formRef.current.setFieldsValue({
      email_destinataire: value,
    });
  };

  openModalEmail = (email) => {
    this.setState({ modalEmailVisible: true, email });
  };

  closeModalEmail = () => {
    this.setState({ modalEmailVisible: false, email: null });
  };

  onKeyUpEmail1 = (e) => {
    this.setState({ email1: e.target.value });
  };

  onKeyUpEmail2 = (e) => {
    this.setState({ email2: e.target.value });
  };

  onChangeEmail1 = (e) => {
    this.handleChange(e.target.checked, this.state.email1);
  };

  onChangeEmail2 = (e) => {
    this.handleChange(e.target.checked, this.state.email2);
  };

  syncPiecesJointes = (liste) => {
    this.setState({ piecesJointes: liste });
  };

  getLangueID = () => {
    const { lot, requete } = this.state;
    let id = 1;

    if (requete) {
      if (
        requete.requete_locataire &&
        requete.requete_locataire.locataire_personne.personne_langue
      ) {
        return requete.requete_locataire.locataire_personne.personne_langue.langue_id;
      }
    } else {
      if (lot.lot_proprietaire && lot.lot_proprietaire.proprietaire_personne.personne_langue) {
        return lot.lot_proprietaire.proprietaire_personne.personne_langue.langue_id;
      }
    }

    return id;
  };

  setObjetMail = (objet) => {
    this.formRef.current.setFieldsValue({
      email_objet: objet,
    });
  };

  setFaq = (template) => {
    const { editorState } = this.state;
    const { templatesLangues } = this.props;
    const langueId = this.getLangueID();

    const tL = templatesLangues.find(
      (item) =>
        item.template_langue_langue.langue_id === langueId &&
        item.template_langue_template.template_id === template.template_id
    );

    this.setState({
      editorState: this.insertText(tL ? tL.template_langue_text : "", editorState),
    });
  };

  insertText = (text, editorState) => {
    let { contentBlocks, entityMap } = htmlToDraft(text);
    let contentState = Modifier.replaceWithFragment(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      ContentState.createFromBlockArray(contentBlocks, entityMap).getBlockMap()
    );

    return EditorState.push(editorState, contentState, "insert-fragment");
  };

  onConfirmDeleteEmail = (e, id) => {
    e.stopPropagation();
    this.props.deleteEmail(id);
  };

  render() {
    const { email, loading, requete, dataEmails, piecesJointes, editorState, modalEmailVisible } =
      this.state;

    const {
      home,
      utilisateur,
      lot,
      data,
      defaultEmail,
      label,
      renduAs,
      langues,
      templates,
      location,
      locationQuittances,
      locationReglements,
      requetesLots,
      lots,
      parametre,
      fraisAgence,
      caution,
      acompte,
      rubriques,
      etapesTemplates,
      templatesLangues,
      isArchive,
    } = this.props;

    const getEmailTitle = (item) => {
      if (!item) {
        return "";
      }
      return `${item.objet} du ${item.date} par ${item.emetteur}`;
    };

    const onRow = (record) => {
      return {
        onClick: (event) => {
          this.openModalEmail(record);
        },
      };
    };

    return (
      <div style={{ padding: 20 }}>
        <Spin spinning={loading} size="large" tip="Chargement...">
          <h4 style={{ textAlign: "center" }}>{label}</h4>
          {lot ? (
            <Row gutter={12}>
              <Col md={14}>
                <Modal
                  footer={null}
                  open={modalEmailVisible}
                  title={getEmailTitle(email)}
                  onCancel={this.closeModalEmail}
                >
                  <Row>
                    <Col md={24}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: email ? email.message : "",
                        }}
                      ></div>
                    </Col>
                  </Row>
                </Modal>
                <FormMailFields
                  home={home}
                  utilisateur={utilisateur}
                  lot={lot}
                  data={data}
                  withForm={false}
                  defaultEmail={defaultEmail}
                  isArchive={isArchive}
                  templates={templates}
                  templatesLangues={templatesLangues}
                  editorState={editorState}
                  parametre={parametre}
                  setObjetMail={this.setObjetMail}
                  saveEmail={this.props.saveEmail}
                  langues={langues}
                  piecesJointesProps={piecesJointes}
                  resetDocument={this.props.resetDocument}
                />
              </Col>
              <Col md={10}>
                <Row gutter={12}>
                  <Col md={12}>
                    <Card size={home ? "small" : "large"} title="Historique courriels">
                      <Table
                        dataSource={dataEmails}
                        style={{ height: home ? 250 : 595 }}
                        pagination={{ pageSize: 5 }}
                        onRow={(record) => onRow(record)}
                      >
                        <ColumnGroup>
                          <Column title="Date" dataIndex="date" key="date" width="20%" />
                        </ColumnGroup>
                        <ColumnGroup>
                          <Column title="Objet" dataIndex="objet" key="objet" width="50%" />
                        </ColumnGroup>
                        {/* <ColumnGroup>
                          <Column title="Em." dataIndex="emetteur" key="emetteur" width="20%" />
                        </ColumnGroup> */}
                        <ColumnGroup>
                          <Column
                            title="#"
                            dataIndex="key"
                            key="key"
                            render={(id) => (
                              <Popconfirm
                                title="Supprimer cet email de la liste ?"
                                onConfirm={(e) => this.onConfirmDeleteEmail(e, id)}
                              >
                                <Button>
                                  <DeleteOutlined />
                                </Button>
                              </Popconfirm>
                            )}
                            width="10%"
                          />
                        </ColumnGroup>
                      </Table>
                    </Card>
                  </Col>
                  <Col md={12}>
                    <Card size={home ? "small" : "large"} title="Documents disponibles">
                      <Table dataSource={null} style={{ height: home ? 250 : 595 }}>
                        <ColumnGroup>
                          <Column title="Nom" dataIndex="nom" key="nom" />
                        </ColumnGroup>
                        <ColumnGroup>
                          <Column title="Type" dataIndex="type" key="type" />
                        </ColumnGroup>
                      </Table>
                    </Card>
                  </Col>
                </Row>
                {home && (
                  <Row>
                    <Col md={12}>
                      <Table
                        size={home ? "small" : "large"}
                        pagination={false}
                        scroll={{ y: home ? 100 : 150 }}
                        rowClassName={() => {
                          return "row-pointer";
                        }}
                        onRow={(record) => {
                          return { onClick: () => this.setFaq(record) };
                        }}
                        dataSource={templates.filter((item) => item.template_type === 2)}
                      >
                        <ColumnGroup>
                          <Column title="FAQ" key="template_libelle" dataIndex="template_libelle" />
                        </ColumnGroup>
                      </Table>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          ) : (
            <Form
              size={home ? "small" : "large"}
              ref={this.formRef}
              className="form-mail"
              onFinish={this.handleCreate}
            >
              <Row gutter={12}>
                <Col md={14}>
                  <Modal
                    footer={null}
                    open={modalEmailVisible}
                    title={getEmailTitle(email)}
                    onCancel={this.closeModalEmail}
                  >
                    <Row>
                      <Col md={24}>
                        <div
                          className={"display-email"}
                          dangerouslySetInnerHTML={{
                            __html: email ? email.message : "",
                          }}
                        ></div>
                      </Col>
                    </Row>
                  </Modal>
                  <FormMailFields
                    home={home}
                    withForm={true}
                    isArchive={isArchive}
                    editorState={editorState}
                    requete={requete}
                    location={location}
                    locationQuittances={locationQuittances}
                    locationReglements={locationReglements}
                    renduAs={renduAs}
                    langues={langues}
                    templates={templates}
                    requetesLots={requetesLots}
                    lots={lots}
                    parametre={parametre}
                    fraisAgence={fraisAgence}
                    caution={caution}
                    acompte={acompte}
                    rubriques={rubriques}
                    etapesTemplates={etapesTemplates}
                    templatesLangues={templatesLangues}
                    piecesJointesProps={piecesJointes}
                    setObjetMail={this.setObjetMail}
                    saveEmail={this.props.saveEmail}
                    onKeyUpEmail1={this.onKeyUpEmail1}
                    onChangeEmail1={this.onChangeEmail1}
                    onKeyUpEmail2={this.onKeyUpEmail2}
                    onChangeEmail2={this.onChangeEmail2}
                    syncPiecesJointes={this.syncPiecesJointes}
                    onEditorStateChange={this.onEditorStateChange}
                    resetDocument={this.props.resetDocument}
                  />
                </Col>
                <Col md={10}>
                  <Row gutter={12}>
                    <Col md={12}>
                      <Card size={home ? "small" : "large"} title="Historique courriels">
                        <Table
                          dataSource={dataEmails}
                          style={{ height: home ? 250 : 595 }}
                          pagination={{ pageSize: 5 }}
                          onRow={(record) => onRow(record)}
                        >
                          <ColumnGroup>
                            <Column title="Date" dataIndex="date" key="date" width="20%" />
                          </ColumnGroup>
                          <ColumnGroup>
                            <Column title="Objet" dataIndex="objet" key="objet" width="50%" />
                          </ColumnGroup>
                          {/* <ColumnGroup>
                            <Column title="Em." dataIndex="emetteur" key="emetteur" width="20%" />
                          </ColumnGroup> */}
                          <ColumnGroup>
                            <Column
                              title="#"
                              dataIndex="key"
                              key="key"
                              render={(id) => (
                                <Popconfirm
                                  title="Supprimer cet email de la liste ?"
                                  onConfirm={(e) => this.onConfirmDeleteEmail(e, id)}
                                  onCancel={(e) => e.stopPropagation()}
                                >
                                  <Button onClick={(e) => e.stopPropagation()}>
                                    <DeleteOutlined />
                                  </Button>
                                </Popconfirm>
                              )}
                              width="10%"
                            />
                          </ColumnGroup>
                        </Table>
                      </Card>
                    </Col>
                    <Col md={12}>
                      <Card size={home ? "small" : "large"} title="Documents disponibles">
                        <Table dataSource={null} style={{ height: home ? 250 : 595 }}>
                          <ColumnGroup>
                            <Column title="Nom" dataIndex="nom" key="nom" />
                          </ColumnGroup>
                          <ColumnGroup>
                            <Column title="Type" dataIndex="type" key="type" />
                          </ColumnGroup>
                        </Table>
                      </Card>
                    </Col>
                  </Row>
                  {home && (
                    <Row>
                      <Col md={12}>
                        <Table
                          size={home ? "small" : "large"}
                          pagination={false}
                          scroll={{ y: home ? 100 : 150 }}
                          rowClassName={() => {
                            return "row-pointer";
                          }}
                          onRow={(record) => {
                            return { onClick: () => this.setFaq(record) };
                          }}
                          dataSource={templates.filter((item) => item.template_type === 2)}
                        >
                          <ColumnGroup>
                            <Column
                              title="FAQ"
                              key="template_libelle"
                              dataIndex="template_libelle"
                            />
                          </ColumnGroup>
                        </Table>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </Form>
          )}
        </Spin>
      </div>
    );
  }
}

export default FormMail;

import React, { Component } from "react";
import { connect } from "react-redux";

import { Button, Card, Col, Divider, Form, Input, Modal, Row, Select, Switch } from "antd";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";

import { initTree } from "../../store/actions/treeAction";
import { saveFraisAgences, updateFraisAgences } from "../../store/actions/fraisAgenceAction";
import { saveCautions, updateCautions } from "../../store/actions/cautionAction";
import { updateParametre } from "../../store/actions/parametreAction";
import { saveImage } from "../../store/actions/photoAction";

import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from "html-to-draftjs";

import Constants from "../../utils/constants";
import { toast } from "react-toastify";
import CustomSelect from "../../components/CustomFormItem/Select";
import { loadComptes } from "../../store/actions/compteAction";

const { Option, OptGroup } = Select;

class Parametre extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalFraisAgence: false,
      modalCaution: false,
      editorState: EditorState.createEmpty(),
      uploadedImages: [],
    };
    this._uploadImageCallBack = this._uploadImageCallBack.bind(this);
  }

  formRef = React.createRef();
  formRefFraisAgence = React.createRef();
  formRefCaution = React.createRef();

  componentDidMount() {
    const societe_id = this.props.utilisateur.societe_id;
    this.props.loadComptes(societe_id);
    this.init();
  }

  init = () => {
    const parametre = this.props.parametre;
    this.formRef.current.setFieldsValue({
      parametre_utilisateur: parametre.parametre_util,
      parametre_email: parametre.parametre_email,
      parametre_mdp: parametre.parametre_mdp,
      parametre_smtp: parametre.parametre_smtp,
      parametre_port: parametre.parametre_port,
      parametre_ssl: parametre.parametre_ssl,
      parametre_verifier_identite: parametre.parametre_verifier_identite,
      parametre_tls: parametre.parametre_tls,
      parametre_authentification: parametre.parametre_authentification,
      parametre_copie_agence: parametre.parametre_copie_agence,
      parametre_cacher_titre: parametre.parametre_cacher_titre,
      parametre_cacher_inactive: parametre.parametre_cacher_inactive,
      parametre_quittancement: parametre.parametre_quittancement,
      parametre_parcours_client: parametre.parametre_parcours_client,
      parametre_nombre_etapes: parametre.parametre_nombre_etapes,
      parametre_pointe_max: parametre.parametre_pointe_max,
      parametre_pointage_auto: parametre.parametre_pointage_auto,
      parametre_signature: parametre.parametre_signature,
      parametre_scheduler_row_height: parametre.parametre_scheduler_row_height,
      parametre_scheduler_row_margin: parametre.parametre_scheduler_row_margin,
      parametre_scheduler_day_before: parametre.parametre_scheduler_day_before,
      parametre_scheduler_day_after: parametre.parametre_scheduler_day_after,
      parametre_scheduler_afficher_reference: parametre.parametre_scheduler_afficher_reference,
      parametre_calcul_arrondi: parametre.parametre_calcul_arrondi,
      parametre_reddition_detaillee: parametre.parametre_reddition_detaillee,
      parametre_langue_id: parametre.parametre_langue_id,
      parametre_depenses_proprietaire: parametre.parametre_depenses_proprietaire,
      parametre_email_copie: parametre.parametre_email_copie,
      parametre_blocage_option: parametre.parametre_blocage_option,
      parametre_reddition_tva: parametre.parametre_reddition_tva,
      parametre_type_contrat: parametre.parametre_type_contrat,
      parametre_provenance_id: parametre.parametre_provenance_id,
      parametre_groupe_id: parametre.parametre_groupe_id,
      parametre_dossier_racine: parametre.parametre_dossier_racine,
      parametre_dossier_utilisateur: parametre.parametre_dossier_utilisateur,
      parametre_solde_honoraires: parametre.parametre_solde_honoraires,
      parametre_honoraires_compte_id: parametre.parametre_honoraires_compte_id,
    });

    let editorState = null;

    if (parametre.parametre_signature) {
      const blocksFromHTML = htmlToDraft(parametre.parametre_signature, (nodeName, node) => {
        if (nodeName === "img" && node instanceof HTMLImageElement) {
          if (node.parentElement instanceof HTMLDivElement) {
            // for image with div element as a parent
            const entityConfig = {};
            const parentElement = node.parentElement;
            if (parentElement.style.float) {
              // check float style property
              entityConfig.alignment = parentElement.style.float;
            } else if (parentElement.style.textAlign) {
              // check float style property
              entityConfig.alignment = parentElement.style.textAlign;
            }

            entityConfig.src = node.getAttribute ? node.getAttribute("src") || node.src : node.src;
            entityConfig.alt = node.alt;
            entityConfig.height = node.style.height;
            entityConfig.width = node.style.width;
            return {
              type: "IMAGE",
              mutability: "MUTABLE",
              data: entityConfig,
            };
          }
        }
      });

      const { contentBlocks, entityMap } = blocksFromHTML;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      editorState = EditorState.createWithContent(contentState);
    } else {
      editorState = EditorState.createEmpty();
    }

    this.setState({ editorState });
  };

  onFinish = (data) => {
    this.parametreFinish(data);
  };

  onAbort = () => {
    this.init();
  };

  parametreFinish = (data) => {
    const parametre = this.props.parametre;

    let text = this.state.editorState
      ? draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
      : "";

    if (text.includes('<p style="text-align:left;"></p>')) {
      text = text.replace('<p style="text-align:left;"></p>', "");
    }

    const jsonParametre = {
      parametre_utilisateur: data.parametre_utilisateur,
      parametre_email: data.parametre_email,
      parametre_mdp: data.parametre_mdp,
      parametre_smtp: data.parametre_smtp,
      parametre_port: data.parametre_port,
      parametre_ssl: data.parametre_ssl,
      parametre_verifier_identite: data.parametre_verifier_identite,
      parametre_tls: data.parametre_tls,
      parametre_authentification: data.parametre_authentification,
      parametre_copie_agence: data.parametre_copie_agence,
      parametre_cacher_titre: data.parametre_cacher_titre,
      parametre_cacher_inactive: data.parametre_cacher_inactive,
      parametre_quittancement: data.parametre_quittancement,
      parametre_parcours_client: data.parametre_parcours_client,
      parametre_nombre_etapes: data.parametre_nombre_etapes,
      parametre_pointe_max: data.parametre_pointe_max,
      parametre_pointage_auto: data.parametre_pointage_auto,
      parametre_rapprochement_compte_id: parametre.parametre_rapprochement_compte_id,
      parametre_date_releve: parametre.parametre_date_releve,
      parametre_montant_releve: parametre.parametre_montant_releve,
      parametre_signature: text,
      parametre_scheduler_row_height: data.parametre_scheduler_row_height,
      parametre_scheduler_row_margin: data.parametre_scheduler_row_margin,
      parametre_scheduler_day_before: data.parametre_scheduler_day_before,
      parametre_scheduler_day_after: data.parametre_scheduler_day_after,
      parametre_scheduler_afficher_reference: data.parametre_scheduler_afficher_reference,
      parametre_calcul_arrondi: data.parametre_calcul_arrondi,
      parametre_reddition_detaillee: data.parametre_reddition_detaillee,
      parametre_langue_id: data.parametre_langue_id,
      parametre_depenses_proprietaire: data.parametre_depenses_proprietaire,
      parametre_email_copie: data.parametre_email_copie,
      parametre_blocage_option: data.parametre_blocage_option,
      parametre_reddition_tva: data.parametre_reddition_tva,
      parametre_type_contrat: data.parametre_type_contrat,
      parametre_provenance_id: data.parametre_provenance_id,
      parametre_groupe_id: data.parametre_groupe_id,
      parametre_dossier_racine: data.parametre_dossier_racine,
      parametre_dossier_utilisateur: data.parametre_dossier_utilisateur,
      parametre_solde_honoraires: data.parametre_solde_honoraires,
      parametre_honoraires_compte_id: data.parametre_honoraires_compte_id,
    };

    this.props
      .updateParametre(jsonParametre, this.props.parametre.parametre_id)
      .then((res) => {
        this.props.initTree(this.props.utilisateur.utilisateur_id);
        toast.success("Paramètres : modifiés !", {
          containerId: "A",
        });
      })
      .catch((err) =>
        toast.error("Paramètres : Échec de la modification !", {
          containerId: "A",
        })
      );
  };

  onFormFraisAgenceValidation = () => {
    this.formRefFraisAgence.current.submit();
  };

  onFormCautionValidation = () => {
    this.formRefCaution.current.submit();
  };

  openModalFraisAgence = (idTypeContrat) => {
    if (this.formRefFraisAgence.current !== null) {
      this.formRefFraisAgence.current.setFieldsValue({
        frais_agence: this.props && this.fraisAgenceSorted(idTypeContrat, this.props.fraisAgence),
      });
    }
    this.setState({ idTypeContrat, modalFraisAgence: true });
  };

  openModalCaution = (idTypeContrat) => {
    if (this.formRefCaution.current !== null) {
      this.formRefCaution.current.setFieldsValue({
        caution: this.props && this.cautionSorted(idTypeContrat, this.props.caution),
      });
    }
    this.setState({ idTypeContrat, modalCaution: true });
  };

  fraisAgenceSorted = (idTypeContrat, fraisAgence) => {
    const fraisAgenceFiltered = fraisAgence.filter(
      (frais) => frais.frais_agence_type_contrat === idTypeContrat
    );
    return fraisAgenceFiltered.sort(function (a, b) {
      if (a.frais_agence_mois_debut < b.frais_agence_mois_debut) {
        return -1;
      } else if (a.frais_agence_mois_debut > b.frais_agence_mois_debut) {
        return 1;
      } else {
        if (a.frais_agence_mois_fin < b.frais_agence_mois_fin) {
          return -1;
        } else if (a.frais_agence_mois_fin > b.frais_agence_mois_fin) {
          return 1;
        } else {
          return 0;
        }
      }
    });
  };

  cautionSorted = (idTypeContrat, caution) => {
    const cautionFiltered = caution.filter((cau) => cau.caution_type_contrat === idTypeContrat);
    return cautionFiltered.sort(function (a, b) {
      if (a.caution_mois_debut < b.caution_mois_debut) {
        return -1;
      } else if (a.caution_mois_debut > b.caution_mois_debut) {
        return 1;
      } else {
        if (a.caution_mois_fin < b.caution_mois_fin) {
          return -1;
        } else if (a.caution_mois_fin > b.caution_mois_fin) {
          return 1;
        } else {
          return 0;
        }
      }
    });
  };

  closeModalFraisAgence = () => {
    this.setState({ idTypeContrat: null, modalFraisAgence: false });
  };

  closeModalCaution = () => {
    this.setState({ idTypeContrat: null, modalCaution: false });
  };

  fraisAgenceFinish = (data) => {
    const jsonFraisAgence = data.frais_agence.map((item) => {
      let result = {
        frais_agence_mois_debut: parseInt(item.frais_agence_mois_debut),
        frais_agence_comparateur_debut: item.frais_agence_comparateur_debut,
        frais_agence_mois_fin: parseInt(item.frais_agence_mois_fin),
        frais_agence_comparateur_fin: item.frais_agence_comparateur_fin,
        frais_agence_pourcentage: parseInt(item.frais_agence_pourcentage),
        frais_agence_pourcentage_type: item.frais_agence_pourcentage_type,
        frais_agence_type_contrat: this.state.idTypeContrat,
        frais_agence_etablissement_id: this.props.utilisateur.etablissement_id,
      };
      if (item.frais_agence_id) {
        result.frais_agence_id = parseInt(item.frais_agence_id);
      }
      return result;
    });

    const jsonUpdateFraisAgence = jsonFraisAgence.filter((item) => item.frais_agence_id);
    const jsonAddFraisAgence = jsonFraisAgence.filter((item) => !item.frais_agence_id);
    const jsonDeleteFraisAgence = this.props.fraisAgence
      .filter((item) => item.frais_agence_type_contrat === this.state.idTypeContrat)
      .filter((item) => {
        return !jsonFraisAgence.some((element) => {
          return element.frais_agence_id === item.frais_agence_id;
        });
      })
      .map((item) => {
        item.frais_agence_societe_id = item.frais_agence_societe.societe_id;
        return item;
      });

    this.closeModalFraisAgence();

    if (jsonDeleteFraisAgence.length || jsonUpdateFraisAgence.length) {
      this.props
        .updateFraisAgence({
          frais_agences: jsonUpdateFraisAgence,
          frais_agences_to_delete: jsonDeleteFraisAgence,
        })
        .catch((err) =>
          toast.error("Frais agence : Échec de la modification !", {
            containerId: "A",
          })
        );
    }
    if (jsonAddFraisAgence.length) {
      this.props.addFraisAgence({ frais_agences: jsonAddFraisAgence }).catch((err) =>
        toast.error("Frais agence : Échec de l'ajout !", {
          containerId: "A",
        })
      );
    }
  };

  cautionFinish = (data) => {
    const jsonCaution = data.caution.map((item) => {
      let result = {
        caution_mois_debut: parseInt(0),
        caution_comparateur_debut: "",
        caution_mois_fin: parseInt(0),
        caution_comparateur_fin: "",
        caution_rubrique_id: parseInt(item.caution_rubrique_id),
        caution_rubrique_liee_id: parseInt(item.caution_rubrique_liee_id),
        caution_pourcentage: parseInt(item.caution_pourcentage),
        caution_type_contrat: this.state.idTypeContrat,
        caution_etablissement_id: this.props.utilisateur.etablissement_id,
      };
      if (item.caution_id) {
        result.caution_id = parseInt(item.caution_id);
      }
      return result;
    });

    const jsonUpdateCaution = jsonCaution.filter((item) => item.caution_id);
    const jsonAddCaution = jsonCaution.filter((item) => !item.caution_id);
    const jsonDeleteCaution = this.props.caution
      .filter((item) => item.caution_type_contrat === this.state.idTypeContrat)
      .filter((item) => {
        return !jsonCaution.some((element) => {
          return element.caution_id === item.caution_id;
        });
      })
      .map((item) => {
        item.caution_etablissement_id = item.caution_etablissement.etablissement_id;
        return item;
      });

    this.closeModalCaution();

    if (jsonDeleteCaution.length || jsonUpdateCaution.length) {
      this.props
        .updateCaution({
          cautions: jsonUpdateCaution,
          cautions_to_delete: jsonDeleteCaution,
        })
        .catch((err) =>
          toast.error("Caution : Échec de la modification !", {
            containerId: "A",
          })
        );
    }
    if (jsonAddCaution.length) {
      this.props.addCaution({ cautions: jsonAddCaution }).catch((err) =>
        toast.error("Caution : Échec de l'ajout !", {
          containerId: "A",
        })
      );
    }
  };

  onEditorStateChange = (editorState) => {
    this.setState({ editorState });
  };

  _uploadImageCallBack(file) {
    // long story short, every time we upload an image, we
    // need to save it to the state so we can get it's data
    // later when we decide what to do with it.

    this.props.saveImage(file);
    // Make sure you have a uploadImages: [] as your default state
    let uploadedImages = this.state.uploadedImages;

    const imageObject = {
      file,
      // localSrc: `${Constants.directory}images/${file.lastModified}_${file.name}`,
      localSrc: `${Constants.mode}://${Constants.domain}:${Constants.port}/api/photos/displayImage/${file.lastModified}_${file.name}`,
    };

    uploadedImages.push(imageObject);

    this.setState({ uploadedImages });

    // We need to return a promise with the image src
    // the img src we will use here will be what's needed
    // to preview it in the browser. This will be different than what
    // we will see in the index.md file we generate.
    return new Promise((resolve, reject) => {
      resolve({ data: { link: imageObject.localSrc } });
    });
  }

  render() {
    const { modalFraisAgence, modalCaution, idTypeContrat, editorState } = this.state;
    const { fraisAgence, caution, rubriques, langues, provenances, groupes, comptes } = this.props;

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };

    const footer = (
      <div>
        <Button
          type="primary"
          danger
          style={{ width: 100, marginBottom: 10 }}
          onClick={this.onAbort}
        >
          Annuler
        </Button>
        <Button
          htmlType="submit"
          type="primary"
          style={{ width: 100, marginLeft: 10, marginBottom: 10 }}
        >
          Valider
        </Button>
      </div>
    );

    const minSelector = (
      <Select>
        <Option value=">">{">"}</Option>
        <Option value=">=">{">="}</Option>
      </Select>
    );

    const maxSelector = (
      <Select>
        <Option value="<">{`<`}</Option>
        <Option value="<=">{`<=`}</Option>
      </Select>
    );

    const pourcentageSelector = (
      <Select style={{ minWidth: "92px" }}>
        <Option value="mensuel">mensuel</Option>
        <Option value="annuel">annuel</Option>
        <Option value="periode">période</Option>
        <Option value="m2">au m²</Option>
      </Select>
    );

    return (
      <>
        <Modal
          visible={modalFraisAgence}
          onCancel={this.closeModalFraisAgence}
          title="Frais d'agence"
          width="1000px"
          footer={null}
        >
          <Form
            ref={this.formRefFraisAgence}
            initialValues={{
              frais_agence: this.fraisAgenceSorted(idTypeContrat, fraisAgence),
            }}
            onFinish={this.fraisAgenceFinish}
          >
            <Form.List name="frais_agence">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field) => {
                    return (
                      <Row gutter={12} key={field.key}>
                        <Col span={7}>
                          <Form.Item name="frais_agence_id" hidden>
                            <Input readOnly>{field.id}</Input>
                          </Form.Item>
                          <Form.Item
                            label="Rubrique : "
                            name={[field.name, "caution_rubrique_id"]}
                            fieldKey={[field.fieldKey, "caution_rubrique_id"]}
                            rules={[
                              {
                                required: true,
                                message: "Rubrique obligatoire",
                              },
                            ]}
                          >
                            <Select
                              // style={{ width: 120 }}
                              optionLabelProp="label"
                              placeholder="RUBRIQUES"
                            >
                              <OptGroup label="Rubrique">
                                {rubriques &&
                                  rubriques.map((item, i) => {
                                    return (
                                      <Option
                                        key={i}
                                        value={item.rubrique_id}
                                        label={`${item.rubrique_code} - ${item.rubrique_libelle}`}
                                      >
                                        <Row>
                                          <Col sm={8}>
                                            {`${item.rubrique_code} - ${item.rubrique_libelle}`}
                                          </Col>
                                        </Row>
                                      </Option>
                                    );
                                  })}
                              </OptGroup>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={5}>
                          <Form.Item
                            label="Entre"
                            name={[field.name, "frais_agence_mois_debut"]}
                            fieldKey={[field.fieldKey, "frais_agence_mois_debut"]}
                            rules={[
                              {
                                required: true,
                                message: "Mois 1 obligatoire",
                              },
                            ]}
                          >
                            <Input
                              type="number"
                              placeholder="MOIS"
                              addonAfter={
                                <Form.Item
                                  name={[field.name, "frais_agence_comparateur_debut"]}
                                  noStyle
                                >
                                  {minSelector}
                                </Form.Item>
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col span={5}>
                          <Form.Item
                            label="et"
                            name={[field.name, "frais_agence_mois_fin"]}
                            fieldKey={[field.fieldKey, "frais_agence_mois_fin"]}
                            rules={[
                              {
                                required: true,
                                message: "Mois 2 obligatoire",
                              },
                            ]}
                          >
                            <Input
                              type="number"
                              placeholder="MOIS"
                              addonAfter={
                                <Form.Item
                                  name={[field.name, "frais_agence_comparateur_fin"]}
                                  noStyle
                                >
                                  {maxSelector}
                                </Form.Item>
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            label=" %: "
                            name={[field.name, "frais_agence_pourcentage"]}
                            fieldKey={[field.fieldKey, "frais_agence_pourcentage"]}
                            rules={[
                              {
                                required: true,
                                message: "Pourcentage obligatoire",
                              },
                            ]}
                          >
                            <Input
                              type="number"
                              placeholder="POURCENTAGE"
                              addonAfter={
                                <Form.Item
                                  name={[field.name, "frais_agence_pourcentage_type"]}
                                  noStyle
                                >
                                  {pourcentageSelector}
                                </Form.Item>
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col span={1}>
                          <Form.Item style={{ marginBottom: 32 }}>
                            <MinusCircleOutlined onClick={() => remove(field.name)} />
                          </Form.Item>
                        </Col>
                      </Row>
                    );
                  })}
                  <Form.Item noStyle>
                    <Button
                      type="dashed"
                      onClick={() =>
                        add({
                          frais_agence_comparateur_debut: ">",
                          frais_agence_comparateur_fin: "<",
                          frais_agence_pourcentage_type: "mensuel",
                        })
                      }
                      block
                      icon={<PlusCircleOutlined />}
                    >
                      Ajouter une règle
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <Button
              // htmlType="submit"
              onClick={() => this.onFormFraisAgenceValidation()}
              type="primary"
              style={{
                width: 100,
                marginTop: 10,
                marginRight: 0,
                marginLeft: "auto",
                display: "block",
              }}
            >
              Valider
            </Button>
          </Form>
        </Modal>

        <Modal
          visible={modalCaution}
          onCancel={this.closeModalCaution}
          title="Caution"
          width="1000px"
          footer={null}
        >
          <Form
            // {...layout}
            ref={this.formRefCaution}
            initialValues={{
              caution: this.cautionSorted(idTypeContrat, caution),
            }}
            onFinish={this.cautionFinish}
          >
            <Form.List name="caution">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field) => {
                    return (
                      <Row gutter={12} key={field.key}>
                        <Col span={7}>
                          <Form.Item name="caution_id" hidden>
                            <Input readOnly>{field.id}</Input>
                          </Form.Item>
                          <Form.Item
                            label="Rubrique : "
                            name={[field.name, "caution_rubrique_id"]}
                            fieldKey={[field.fieldKey, "caution_rubrique_id"]}
                            rules={[
                              {
                                required: true,
                                message: "Rubrique obligatoire",
                              },
                            ]}
                          >
                            <Select
                              // style={{ width: 120 }}
                              optionLabelProp="label"
                              placeholder="RUBRIQUES"
                            >
                              <OptGroup label="Rubrique">
                                {rubriques &&
                                  rubriques.map((item, i) => {
                                    return (
                                      <Option
                                        key={i}
                                        value={item.rubrique_id}
                                        label={`${item.rubrique_code} - ${item.rubrique_libelle}`}
                                      >
                                        <Row>
                                          <Col sm={8}>
                                            {`${item.rubrique_code} - ${item.rubrique_libelle}`}
                                          </Col>
                                        </Row>
                                      </Option>
                                    );
                                  })}
                              </OptGroup>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={7}>
                          <Form.Item
                            label="% : "
                            name={[field.name, "caution_pourcentage"]}
                            fieldKey={[field.fieldKey, "caution_pourcentage"]}
                            rules={[
                              {
                                required: true,
                                message: "Pourcentage obligatoire",
                              },
                            ]}
                          >
                            <Input type="number" placeholder="Pourcentage" />
                          </Form.Item>
                        </Col>
                        <Col span={7}>
                          <Form.Item
                            label="Sur : "
                            name={[field.name, "caution_rubrique_liee_id"]}
                            fieldKey={[field.fieldKey, "caution_rubrique_liee_id"]}
                            rules={[
                              {
                                required: true,
                                message: "Rubrique obligatoire",
                              },
                            ]}
                          >
                            <Select
                              // style={{ width: 120 }}
                              optionLabelProp="label"
                              placeholder="RUBRIQUES"
                            >
                              <OptGroup label="Rubrique">
                                {rubriques &&
                                  rubriques.map((item, i) => {
                                    return (
                                      <Option
                                        key={i}
                                        value={item.rubrique_id}
                                        label={`${item.rubrique_code} - ${item.rubrique_libelle}`}
                                      >
                                        <Row>
                                          <Col sm={8}>
                                            {`${item.rubrique_code} - ${item.rubrique_libelle}`}
                                          </Col>
                                        </Row>
                                      </Option>
                                    );
                                  })}
                              </OptGroup>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={3}>
                          <Form.Item style={{ marginBottom: 32 }}>
                            <MinusCircleOutlined onClick={() => remove(field.name)} />
                          </Form.Item>
                        </Col>
                      </Row>
                    );
                  })}
                  <Form.Item noStyle>
                    <Button
                      type="dashed"
                      onClick={() =>
                        add({
                          caution_comparateur_debut: ">",
                          caution_comparateur_fin: "<",
                        })
                      }
                      block
                      icon={<PlusCircleOutlined />}
                    >
                      Ajouter une règle
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <Button
              // htmlType="submit"
              onClick={() => this.onFormCautionValidation()}
              type="primary"
              style={{
                width: 100,
                marginTop: 10,
                marginRight: 0,
                marginLeft: "auto",
                display: "block",
              }}
            >
              Valider
            </Button>
          </Form>
        </Modal>

        <Row>
          <Col offset={3} span={18}>
            <Form {...layout} ref={this.formRef} onFinish={this.onFinish}>
              <Card title={"Paramètres Utilisateur"} actions={[footer]}>
                <Divider orientation="left">Mail</Divider>
                <Row>
                  <Col offset={1} span={11}>
                    <Row>
                      <Col offset={1} span={23}>
                        <Form.Item name="parametre_utilisateur">
                          <Input placeholder="Utilisateur" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col offset={1} span={23}>
                        <Form.Item
                          name="parametre_email"
                          rules={[
                            {
                              type: "email",
                              message: "Veuillez entrer une adresse valide !",
                            },
                          ]}
                        >
                          <Input placeholder="Email" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col offset={1} span={23}>
                        <Form.Item name="parametre_mdp">
                          <Input.Password placeholder="Mot de passe" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col offset={1} span={23}>
                        <Form.Item name="parametre_smtp">
                          <Input placeholder="SMTP" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col offset={1} span={23}>
                        <Form.Item name="parametre_port">
                          <Input placeholder="Port" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col offset={1} span={23}>
                        <Form.Item name="parametre_email_copie">
                          <Input placeholder="Email copie" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col offset={1} span={11}>
                    <Row>
                      <Col offset={1} span={9}>
                        SSL
                      </Col>
                      <Col offset={1} span={4}>
                        <Form.Item name="parametre_ssl" valuePropName="checked">
                          <Switch className="switch-parametre" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col offset={1} span={9}>
                        Vérifier identité
                      </Col>
                      <Col offset={1} span={4}>
                        <Form.Item name="parametre_verifier_identite" valuePropName="checked">
                          <Switch className="switch-parametre" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col offset={1} span={9}>
                        TLS
                      </Col>
                      <Col offset={1} span={4}>
                        <Form.Item name="parametre_tls" valuePropName="checked">
                          <Switch className="switch-parametre" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col offset={1} span={9}>
                        Authentification
                      </Col>
                      <Col offset={1} span={4}>
                        <Form.Item name="parametre_authentification" valuePropName="checked">
                          <Switch className="switch-parametre" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col offset={1} span={9}>
                        Copie à l'agence
                      </Col>
                      <Col offset={1} span={4}>
                        <Form.Item name="parametre_copie_agence" valuePropName="checked">
                          <Switch className="switch-parametre" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Divider orientation="left">Tarifs & Contrats</Divider>
                <Row style={{ paddingLeft: "20px" }}>
                  <Col offset={1} span={5}>
                    Arrondir les montants lors du calcul du tarif à la nuitée
                  </Col>
                  <Col span={3}>
                    <Form.Item name="parametre_calcul_arrondi" valuePropName="checked">
                      <Switch className="switch-parametre" />
                    </Form.Item>
                  </Col>
                  <Col offset={4} span={5}>
                    Dépenses propriétaire lors de la création du contrat
                  </Col>
                  <Col span={3}>
                    <Form.Item name="parametre_depenses_proprietaire" valuePropName="checked">
                      <Switch className="switch-parametre" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row style={{ paddingLeft: "20px" }}>
                  <Col offset={1} span={5}>
                    Blocage d'une option pour les autres lots
                  </Col>
                  <Col span={3}>
                    <Form.Item name="parametre_blocage_option" valuePropName="checked">
                      <Switch className="switch-parametre" />
                    </Form.Item>
                  </Col>
                </Row>
                <Divider orientation="left">Planning</Divider>
                <Row style={{ paddingLeft: "20px" }}>
                  <Col offset={1} span={5}>
                    Hauteur des lignes
                  </Col>
                  <Col span={3}>
                    <Form.Item name="parametre_scheduler_row_height">
                      <Input type="number" placeholder="HAUTEUR" min={1} />
                    </Form.Item>
                  </Col>
                  <Col offset={4} span={5}>
                    Marge des évènements
                  </Col>
                  <Col span={3}>
                    <Form.Item name="parametre_scheduler_row_margin">
                      <Input type="number" placeholder="MARGE" min={0} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row style={{ paddingLeft: "20px" }}>
                  <Col offset={1} span={5}>
                    Jours avant la date du jour
                  </Col>
                  <Col span={3}>
                    <Form.Item name="parametre_scheduler_day_before">
                      <Input type="number" min={0} />
                    </Form.Item>
                  </Col>
                  <Col offset={4} span={5}>
                    Jours après la date du jour
                  </Col>
                  <Col span={3}>
                    <Form.Item name="parametre_scheduler_day_after">
                      <Input type="number" min={0} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row style={{ paddingLeft: "20px" }}>
                  <Col offset={1} span={5}>
                    Afficher la référence du lot
                  </Col>
                  <Col span={3}>
                    <Form.Item
                      name="parametre_scheduler_afficher_reference"
                      valuePropName="checked"
                    >
                      <Switch className="switch-parametre" />
                    </Form.Item>
                  </Col>
                </Row>
                <Divider orientation="left">Arbre</Divider>
                <Row style={{ paddingLeft: "20px" }}>
                  <Col offset={1} span={5}>
                    Cacher le titre des étapes de niveau 1
                  </Col>
                  <Col span={3}>
                    <Form.Item name="parametre_cacher_titre" valuePropName="checked">
                      <Switch className="switch-parametre" />
                    </Form.Item>
                  </Col>
                  <Col offset={4} span={5}>
                    Cacher les étapes inactives
                  </Col>
                  <Col span={3}>
                    <Form.Item name="parametre_cacher_inactive" valuePropName="checked">
                      <Switch className="switch-parametre" />
                    </Form.Item>
                  </Col>
                </Row>
                <Divider orientation="left">Quittancement</Divider>
                <Row style={{ paddingLeft: "20px" }}>
                  <Col offset={1} span={5}>
                    Activer le quittancement
                  </Col>
                  <Col span={3}>
                    <Form.Item name="parametre_quittancement" valuePropName="checked">
                      <Switch className="switch-parametre" />
                    </Form.Item>
                  </Col>
                </Row>
                <Divider orientation="left">Rapport de gestion</Divider>
                <Row style={{ paddingLeft: "20px" }}>
                  <Col offset={1} span={5}>
                    Rapport de gestion détaillé
                  </Col>
                  <Col span={3}>
                    <Form.Item name="parametre_reddition_detaillee" valuePropName="checked">
                      <Switch className="switch-parametre" />
                    </Form.Item>
                  </Col>
                  <Col offset={4} span={5}>
                    Afficher TVA
                  </Col>
                  <Col span={3}>
                    <Form.Item name="parametre_reddition_tva" valuePropName="checked">
                      <Switch className="switch-parametre" />
                    </Form.Item>
                  </Col>
                </Row>
                <Divider orientation="left">Parcours client</Divider>
                <Row style={{ paddingLeft: "20px" }}>
                  <Col offset={1} span={5}>
                    Activer le parcours client
                  </Col>
                  <Col span={3}>
                    <Form.Item name="parametre_parcours_client" valuePropName="checked">
                      <Switch className="switch-parametre" />
                    </Form.Item>
                  </Col>
                  <Col offset={4} span={5}>
                    Nombre d'étapes à afficher
                  </Col>
                  <Col span={3}>
                    <Form.Item name="parametre_nombre_etapes">
                      <Input type="number" placeholder="ETAPES" min={1} />
                    </Form.Item>
                  </Col>
                </Row>
                <Divider orientation="left">Pointe</Divider>
                <Row style={{ paddingLeft: "20px" }}>
                  <Col offset={1} span={5}>
                    Montant Garantie Financière
                  </Col>
                  <Col span={3}>
                    <Form.Item name="parametre_pointe_max">
                      <Input type="number" placeholder="MONTANT" min={0} />
                    </Form.Item>
                  </Col>
                </Row>
                <Divider orientation="left">Rapprochement bancaire</Divider>
                <Row style={{ paddingLeft: "20px" }}>
                  <Col offset={1} span={5}>
                    Pointage automatique
                  </Col>
                  <Col span={3}>
                    <Form.Item name="parametre_pointage_auto" valuePropName="checked">
                      <Switch className="switch-parametre" />
                    </Form.Item>
                  </Col>
                </Row>
                <Divider orientation="left">Demandes (valeur par défaut)</Divider>
                <Row style={{ paddingLeft: "20px" }}>
                  <Col offset={1} span={5}>
                    Type de contrat
                  </Col>
                  <Col span={4}>
                    <CustomSelect
                      label="Type de contrat"
                      inputName="type_contrat"
                      formItemName="parametre_type_contrat"
                      allowClear
                      objectValue={this.props.parametre.parametre_type_contrat}
                    >
                      <OptGroup label="Type de contrat">
                        <Option key={1} value={1} label={"Alur"}>
                          Alur
                        </Option>
                        <Option key={2} value={2} label={"Long terme"}>
                          Long terme
                        </Option>
                        <Option key={3} value={3} label={"Court terme"}>
                          Court terme
                        </Option>
                      </OptGroup>
                    </CustomSelect>
                  </Col>
                </Row>
                <Row style={{ paddingLeft: "20px" }}>
                  <Col offset={1} span={5}>
                    Provenance
                  </Col>
                  <Col span={4}>
                    <CustomSelect
                      label="Provenance"
                      inputName="provenance"
                      formItemName="parametre_provenance_id"
                      allowClear
                      objectValue={this.props.parametre.parametre_provenance_id}
                    >
                      <OptGroup label="Provenance">
                        {provenances &&
                          provenances.map((item, i) => {
                            return (
                              <Option
                                key={i}
                                value={item.provenance_id}
                                label={item.provenance_libelle}
                              >
                                <Row>
                                  <Col sm={8}>{item.provenance_libelle}</Col>
                                </Row>
                              </Option>
                            );
                          })}
                      </OptGroup>
                    </CustomSelect>
                  </Col>
                </Row>
                <Row style={{ paddingLeft: "20px" }}>
                  <Col offset={1} span={5}>
                    Groupe
                  </Col>
                  <Col span={4}>
                    <CustomSelect
                      label="Groupe"
                      inputName="groupe"
                      formItemName="parametre_groupe_id"
                      allowClear
                      objectValue={this.props.parametre.parametre_groupe_id}
                    >
                      <OptGroup label="Groupe">
                        {groupes &&
                          groupes
                            .filter((groupe) => groupe.groupe_visible === true)
                            .map((item, i) => {
                              return (
                                <Option key={i} value={item.groupe_id} label={item.groupe_libelle}>
                                  <Row>
                                    <Col sm={8}>{item.groupe_libelle}</Col>
                                  </Row>
                                </Option>
                              );
                            })}
                      </OptGroup>
                    </CustomSelect>
                  </Col>
                </Row>
                <Divider orientation="left">Honoraires</Divider>
                <Row style={{ paddingLeft: "20px" }}>
                  <Col offset={1} span={5}>
                    Solde honoraires fin de mois
                  </Col>
                  <Col span={18}>
                    <Form.Item name="parametre_solde_honoraires" valuePropName="checked">
                      <Switch className="switch-parametre" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row style={{ paddingLeft: "20px" }}>
                  <Col offset={1} span={5}>
                    Compte intermédiaire
                  </Col>
                  <Col span={9}>
                    <CustomSelect
                      label="Compte"
                      inputName="compte"
                      formItemName="parametre_honoraires_compte_id"
                      allowClear
                      objectValue={this.props.parametre.parametre_honoraires_compte_id}
                    >
                      <OptGroup label="Compte">
                        {comptes &&
                          comptes
                            .filter((item) => item.compte_type_compte === "G")
                            .sort((a, b) =>
                              a.compte_compte_general.localeCompare(b.compte_compte_general)
                            )
                            .map((item, i) => {
                              return (
                                <Option
                                  key={i}
                                  value={item.compte_id}
                                  label={`${item.compte_compte_general} - ${item.compte_libelle}`}
                                >
                                  <Row>
                                    <Col
                                      sm={8}
                                    >{`${item.compte_compte_general} - ${item.compte_libelle}`}</Col>
                                  </Row>
                                </Option>
                              );
                            })}
                      </OptGroup>
                    </CustomSelect>
                  </Col>
                </Row>

                <Divider orientation="left">Dossier documents</Divider>
                <Row style={{ paddingLeft: "20px" }}>
                  <Col offset={1} span={5}>
                    Racine
                  </Col>
                  <Col span={18}>
                    <Form.Item name="parametre_dossier_racine">
                      <Input placeholder="RACINE" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row style={{ paddingLeft: "20px" }}>
                  <Col offset={1} span={5}>
                    Utilisateur
                  </Col>
                  <Col span={9}>
                    <Form.Item name="parametre_dossier_utilisateur">
                      <Input placeholder="UTILISATEUR" />
                    </Form.Item>
                  </Col>
                </Row>
                <Divider orientation="left">Divers</Divider>
                <Row style={{ paddingLeft: "20px" }}>
                  <Col offset={1} span={5}>
                    Langue par défaut
                  </Col>
                  <Col span={4}>
                    <CustomSelect
                      label="Langue"
                      inputName="langue"
                      formItemName="parametre_langue_id"
                      allowClear
                      objectValue={this.props.parametre.parametre_langue_id}
                    >
                      <OptGroup label="Langue">
                        {langues &&
                          langues.map((item, i) => {
                            return (
                              <Option key={i} value={item.langue_id} label={item.langue_libelle}>
                                <Row>
                                  <Col sm={8}>{item.langue_libelle}</Col>
                                </Row>
                              </Option>
                            );
                          })}
                      </OptGroup>
                    </CustomSelect>
                  </Col>
                </Row>
                <Divider orientation="left">Cautions</Divider>
                <Row style={{ paddingLeft: "20px" }}>
                  <Col offset={1} span={23}>
                    <Button style={{ marginRight: 5 }} onClick={() => this.openModalCaution(1)}>
                      ALUR
                    </Button>
                    <Button style={{ marginRight: 5 }} onClick={() => this.openModalCaution(2)}>
                      LONGUE DURÉE
                    </Button>
                    <Button style={{ marginRight: 5 }} onClick={() => this.openModalCaution(3)}>
                      COURTE DURÉE
                    </Button>
                  </Col>
                </Row>
                <Divider orientation="left">Frais d'agence</Divider>
                <Row style={{ paddingLeft: "20px" }}>
                  <Col offset={1} span={23}>
                    <Button style={{ marginRight: 5 }} onClick={() => this.openModalFraisAgence(1)}>
                      ALUR
                    </Button>
                    <Button style={{ marginRight: 5 }} onClick={() => this.openModalFraisAgence(2)}>
                      LONGUE DURÉE
                    </Button>
                    <Button style={{ marginRight: 5 }} onClick={() => this.openModalFraisAgence(3)}>
                      COURTE DURÉE
                    </Button>
                  </Col>
                </Row>
                <Divider orientation="left">Signature de mail</Divider>
                <Row style={{ paddingLeft: "20px" }}>
                  <Col offset={1} span={23}>
                    <Form.Item name="parametre_signature">
                      <Editor
                        style={{ height: "600px" }}
                        editorState={editorState}
                        toolbarClassName="toolbar-class"
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-template"
                        onEditorStateChange={this.onEditorStateChange}
                        stripPastedStyles={true}
                        toolbar={{
                          image: {
                            alt: { present: true, mandatory: false },
                            previewImage: true,
                            uploadEnabled: true,
                            uploadCallback: this._uploadImageCallBack,
                          },
                          inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Form>
          </Col>
        </Row>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    initTree: (utilisateur_id) => dispatch(initTree(utilisateur_id)),
    loadComptes: (societe_id) => dispatch(loadComptes(societe_id)),
    addFraisAgence: (jsonData, id) => dispatch(saveFraisAgences(jsonData, id)),
    updateFraisAgence: (jsonData, id) => dispatch(updateFraisAgences(jsonData, id)),
    addCaution: (jsonData, id) => dispatch(saveCautions(jsonData, id)),
    updateCaution: (jsonData, id) => dispatch(updateCautions(jsonData, id)),
    updateParametre: (jsonData, id) => dispatch(updateParametre(jsonData, id)),
    saveImage: (jsonData) => dispatch(saveImage(jsonData)),
  };
};

// mapper le state aux props du component
const mapStateToProps = (state) => {
  return {
    utilisateur: state.utilisateur.data,
    fraisAgence: state.fraisAgence.data,
    caution: state.caution.data,
    rubriques: state.rubriques.data,
    langues: state.langues.data,
    provenances: state.provenances.data,
    groupes: state.groupes.data,
    comptes: state.comptes.data,
    parametre: state.parametre.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Parametre);

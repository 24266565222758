import React, { Component } from "react";
import { connect } from "react-redux";
import { Spin } from "antd";

import { loadComptes } from "../../../store/actions/compteAction";
import { loadEcritures, saveEcriture } from "../../../store/actions/ecritureAction";
import { deleteDocument, processWord } from "../../../store/actions/documentAction";

import FormHonoraires from "../../../components/FormHonoraires";

class Honoraires extends Component {
  componentDidMount() {
    const societe_id = this.props.utilisateur.societe_id;
    this.props.loadComptes(societe_id);
    this.props.loadEcritures(societe_id);
  }

  render() {
    const { utilisateur, parametre, comptes, ecritures, journals, tags, words } = this.props;

    return (
      <Spin size="large" tip="Chargement en cours..." spinning={!comptes || !ecritures}>
        {comptes && ecritures && (
          <FormHonoraires
            utilisateur={utilisateur}
            parametre={parametre}
            comptes={comptes}
            ecritures={ecritures}
            journals={journals}
            tags={tags}
            words={words}
            saveEcriture={this.props.saveEcriture}
            processWord={this.props.processWord}
            deleteDocument={this.props.deleteDocument}
          />
        )}
      </Spin>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadComptes: (societe_id) => dispatch(loadComptes(societe_id)),
    loadEcritures: (societe_id) => dispatch(loadEcritures(societe_id)),
    saveEcriture: (ecriture) => dispatch(saveEcriture(ecriture)),
    processWord: (jsonData, idWord, idDossier) =>
      dispatch(processWord(jsonData, idWord, idDossier)),
    deleteDocument: (id) => dispatch(deleteDocument(id)),
  };
};

// mapper le state aux props du component
const mapStateToProps = (state) => {
  return {
    utilisateur: state.utilisateur.data,
    parametre: state.parametre.data,
    comptes: state.comptes.data,
    ecritures: state.ecritures.data,
    journals: state.journals.data,
    tags: state.tags.data,
    words: state.words.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Honoraires);

import React, { useRef, useState } from "react";
import { Button, Col, DatePicker, Form, Modal, Row, Select, Spin, Table, Typography } from "antd";
import CustomInput from "../CustomFormItem/Input";
import CustomDatePicker from "../CustomFormItem/DatePicker";
import CustomSelect from "../CustomFormItem/Select";
import FloatLabel from "../FloatLabel";

import { toast } from "react-toastify";

import constants from "../../utils/constants";

import dayjs from "dayjs";
const dateFormat = "DD/MM/YYYY";

const { OptGroup, Option } = Select;
const { Text, Title } = Typography;

const FormHonoraires = (props) => {
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [date, setDate] = useState(dayjs());
  const [modele, setModele] = useState(null);
  const [modal, setModal] = useState(false);
  const [document, setDocument] = useState(null);
  const { utilisateur, parametre, comptes, ecritures, journals, tags, words } = props;

  // Référence du formulaire
  const formRef = useRef();

  const compteBanque = comptes.find(
    (compte) => compte.compte_type_compte !== "A" && compte.compte_compte_general.startsWith("5")
  );

  const getSoldeCompte = (id) => {
    return ecritures
      .filter((ecriture) =>
        dayjs(ecriture.ecriture_date_ecriture, "DD/MM/YYYY").isSameOrBefore(date)
      )
      .flatMap((ecriture) => ecriture.ecriture_ligne)
      .filter((ligne) => ligne.ecriture_ligne_compte_id === id)
      .reduce(
        (total, ligne) =>
          total +
          parseFloat(ligne.ecriture_ligne_montant_debit - ligne.ecriture_ligne_montant_credit),
        0
      );
  };

  const getTotalComptes = () => {
    return selectedRows
      .map((compte) => compte.compte_id)
      .reduce((total, compte_id) => {
        const compteTotal = getSoldeCompte(compte_id);
        return total + compteTotal;
      }, 0);
  };

  const onChangeDate = (e) => {
    setDate(e);
  };

  const handleOnFinish = (data) => {
    const journal = journals.find(
      (journal) => journal.journal_compte?.compte_id === data.compte_id
    );
    journal
      ? createEcriture(data, journal.journal_id)
      : toast.error("Aucun journal trouvé !", { containerId: "A" });
  };

  const createEcriture = (data, id) => {
    const ecritureLignes = [];
    let montant = 0;
    selectedRows.forEach((row) => {
      const soldeCompte = getSoldeCompte(row.compte_id);
      if (soldeCompte < 0) {
        const ligneDebit = {
          ecriture_ligne_libelle: data.libelle,
          ecriture_ligne_montant_debit: parseFloat(Math.abs(soldeCompte)).toFixed(2),
          ecriture_ligne_montant_credit: 0,
          ecriture_ligne_compte_id: row.compte_id,
          ecriture_ligne_locataire_code: "",
          ecriture_ligne_en_reddition: false,
        };
        montant += parseFloat(soldeCompte);
        ecritureLignes.push(ligneDebit);
      }
    });
    let contrepartie = null;
    let dateEcriture = null;
    if (parametre.parametre_solde_honoraires) {
      contrepartie = parametre.parametre_honoraires_compte_id;
      dateEcriture = dayjs(date, "DD/MM/YYYY").format("DD/MM/YYYY");
    } else {
      contrepartie = data.compte_id;
      dateEcriture = dayjs(data.date_ecriture).format("DD/MM/YYYY");
    }
    const ligneCredit = {
      ecriture_ligne_libelle: data.libelle,
      ecriture_ligne_montant_debit: 0,
      ecriture_ligne_montant_credit: parseFloat(Math.abs(montant)).toFixed(2),
      ecriture_ligne_compte_id: contrepartie,
      ecriture_ligne_locataire_code: "",
      ecriture_ligne_en_reddition: false,
    };
    ecritureLignes.push(ligneCredit);
    const ecriture = {
      ecriture_journal_id: id,
      ecriture_date_ecriture: dateEcriture,
      ecriture_libelle: data.libelle,
      ecriture_ligne: ecritureLignes,
    };
    props
      .saveEcriture(ecriture)
      .then(() => {
        if (parametre.parametre_solde_honoraires) {
          createEcriture2(data, id, montant);
        } else {
          reset();
          toast.success("Écriture créée !", { containerId: "A" });
        }
      })
      .catch(() => toast.error("Erreur création écriture honoraires !", { containerId: "A" }));
  };

  const createEcriture2 = (data, id, montant) => {
    const ecritureLignes = [];
    const ligneDebit = {
      ecriture_ligne_libelle: "Versement compte courant",
      ecriture_ligne_montant_debit: parseFloat(Math.abs(montant)).toFixed(2),
      ecriture_ligne_montant_credit: 0,
      ecriture_ligne_compte_id: parametre.parametre_honoraires_compte_id,
      ecriture_ligne_locataire_code: "",
      ecriture_ligne_en_reddition: false,
    };
    ecritureLignes.push(ligneDebit);
    const ligneCredit = {
      ecriture_ligne_libelle: "Versement compte courant",
      ecriture_ligne_montant_debit: 0,
      ecriture_ligne_montant_credit: parseFloat(Math.abs(montant)).toFixed(2),
      ecriture_ligne_compte_id: data.compte_id,
      ecriture_ligne_locataire_code: "",
      ecriture_ligne_en_reddition: false,
    };
    ecritureLignes.push(ligneCredit);
    const ecriture = {
      ecriture_journal_id: id,
      ecriture_date_ecriture: dayjs(data.date_ecriture).format("DD/MM/YYYY"),
      ecriture_libelle: "Versement compte courant",
      ecriture_ligne: ecritureLignes,
    };
    props
      .saveEcriture(ecriture)
      .then(() => {
        reset();
        toast.success("Écritures créées !", { containerId: "A" });
      })
      .catch(() => toast.error("Erreur création des écritures honoraires !", { containerId: "A" }));
  };

  const reset = () => {
    formRef.current.resetFields();
    setSelectedRowKeys([]);
    setSelectedRows([]);
  };

  const closeModal = (document = null, deleteDoc = false) => {
    document && deleteDoc && props.deleteDocument(document.document_id);
    setModal(false);
  };

  const handleCreatePdf = () => {
    const word = words.find((word) => word.word_id === modele);
    if (word) {
      createDocx(word);
      setLoading(true);
    } else {
      toast.error("Veuillez sélectionner un modèle", { containerId: "A" });
    }
  };

  const createDocx = (word) => {
    const strDate = dayjs().format("YYYY-MM-DD_HH-mm-ss");
    let documentName = `${utilisateur.utilisateur_etablissement.etablissement_societe.societe_raison_sociale}-FACTURE-${strDate}`;
    documentName = documentName.replace("&", "").replace(/\s/g, "-");

    let blocHonoraires = "";
    let montant = 0;
    selectedRows.forEach((row) => {
      const soldeCompte = getSoldeCompte(row.compte_id);
      blocHonoraires += `${row.compte_libelle} : ${parseFloat(Math.abs(soldeCompte)).toLocaleString(
        "fr-FR",
        { style: "currency", currency: "EUR" }
      )}<br />`;
      montant += parseFloat(soldeCompte);
    });

    const montantTTC = parseFloat(Math.abs(montant));
    const montantHT = getMontantHT(montantTTC, 0.2);
    const montantTVA = montantTTC - montantHT;

    const data = {
      document_nom: `${documentName}.pdf`,
      document_word_nom: `${documentName}.docx`,
      document_dossier_id: utilisateur.dossier_id,
      document_tag_id: word.word_tag_id ? word.word_tag_id : tags[0].tag_id,
      libelle_type: "COMPTABILITE",
      bloc_honoraires: convertToPlain(blocHonoraires),
      total_honoraires: montantTTC.toFixed(2),
      total_honoraires_HT: montantHT.toFixed(2),
      total_honoraires_TVA: montantTVA.toFixed(2),
      lot_photos: [],
    };

    if (utilisateur.dossier_id) {
      props.processWord(data, word.word_id, utilisateur.dossier_id).then((res) => {
        setDocument(res);
        setModal(true);
        setLoading(false);
      });
    } else {
      toast.error("Dossier société introuvable", { containerId: "A" });
      setLoading(false);
    }
  };

  function convertToPlain(rtf) {
    let txt = rtf.replace(/<br ?\/?>/g, "\n");
    return txt.replace(/(<([^>]+)>)/gi, "");
  }

  function getMontantHT(montant, tauxTVA) {
    return parseFloat(montant / (1 + tauxTVA));
  }

  let columns = [
    {
      title: "Compte",
      dataIndex: "compte_compte_general",
      key: "compte_compte_general",
      width: "25%",
    },
    {
      title: "Compte",
      dataIndex: "compte_libelle",
      key: "compte_libelle",
      width: "50%",
    },
    {
      title: "Solde",
      dataIndex: "compte_id",
      key: "solde",
      render: (id) =>
        parseFloat(getSoldeCompte(id)).toLocaleString("fr-FR", {
          style: "currency",
          currency: "EUR",
        }),
      width: "25%",
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
    },
    selectedRowKeys: selectedRowKeys,
    selectedRows: selectedRows,
  };

  return (
    <>
      <Modal
        width={800}
        footer={null}
        title="Facture"
        open={modal}
        onCancel={() => closeModal(document, true)}
      >
        <Spin spinning={loading} tip="Création pdf...">
          <Row gutter={12}>
            {document && (
              <Col span={6}>
                <Button
                  style={{ width: "100%", marginBottom: 10 }}
                  href={`${constants.directory}documents/${document.document_dossier.dossier_url}${document.document_tag.tag_libelle}/${document.document_nom}`}
                  target="_blank"
                >
                  Télécharger Facture PDF
                </Button>
              </Col>
            )}
            <Col span={6}>
              <Button
                style={{ width: "100%", marginBottom: 10 }}
                onClick={() => closeModal(document, true)}
              >
                Annuler
              </Button>
            </Col>
          </Row>
        </Spin>
      </Modal>

      <div style={{ padding: 20 }}>
        <Row>
          <Title level={2}>
            <strong>Versement des honoraires</strong>
          </Title>
        </Row>
        <Row>
          <Col span={6}>
            <FloatLabel label="Date butoir" name="date" value={date}>
              <DatePicker
                format={dateFormat}
                defaultValue={date}
                onChange={(e) => onChangeDate(e)}
              />
            </FloatLabel>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={24}>
            <Table
              dataSource={comptes.filter((compte) => compte.compte_honoraire)}
              columns={columns}
              rowKey="compte_id"
              rowSelection={{
                type: "checkbox",
                ...rowSelection,
              }}
              pagination={false}
              summary={() => {
                return (
                  <Table.Summary fixed>
                    <Table.Summary.Row>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <Text style={{ float: "right" }}>
                          <strong>SOLDE DES COMPTES :</strong>
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <Text style={{ float: "right" }}>
                          <strong>
                            {parseFloat(getTotalComptes()).toLocaleString("fr-FR", {
                              style: "currency",
                              currency: "EUR",
                            })}
                          </strong>
                        </Text>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                );
              }}
            />
          </Col>
        </Row>
        <Form
          ref={formRef}
          initialValues={{ compte_id: compteBanque?.compte_id }}
          onFinish={handleOnFinish}
          style={{ marginTop: 20 }}
        >
          <Row gutter={12}>
            <Col span={6}>
              <CustomSelect
                label="Compte"
                inputName="compte_id"
                formItemName="compte_id"
                objectValue={compteBanque?.compte_id}
                rules={{ required: true, message: "Compte obligatoire" }}
              >
                <OptGroup label="Compte">
                  {comptes &&
                    comptes
                      .filter((item) => item.compte_type_compte !== "A")
                      .map((item, i) => {
                        return (
                          <Option
                            key={i}
                            value={item.compte_id}
                            label={`${item.compte_compte_general} - ${item.compte_libelle}`}
                          >
                            <Row>
                              <Col
                                sm={8}
                              >{`${item.compte_compte_general} - ${item.compte_libelle}`}</Col>
                            </Row>
                          </Option>
                        );
                      })}
                </OptGroup>
              </CustomSelect>
            </Col>
            <Col offset={8} span={8}>
              <CustomSelect
                label="Choix modèle facture"
                inputName="pdf_template"
                formItemName="pdf_template"
                onSelect={(modele) => setModele(modele)}
              >
                <OptGroup label="Modèle">
                  {words &&
                    words
                      .filter((item) => item.word_type === 6)
                      .map((item, i) => {
                        return (
                          <Option key={i} value={item.word_id} label={`${item.word_nom}`}>
                            <Row>
                              <Col sm={8}>{`${item.word_nom}`}</Col>
                            </Row>
                          </Option>
                        );
                      })}
                </OptGroup>
              </CustomSelect>
            </Col>
            <Col span={2}>
              <Button
                size="middle"
                type="primary"
                disabled={selectedRows.length === 0}
                loading={loading}
                onClick={() => handleCreatePdf()}
              >
                Générer PDF
              </Button>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={6}>
              <CustomDatePicker
                label="Date d'écriture"
                inputName="date_ecriture"
                formItemName="date_ecriture"
                rules={{ required: true, message: "Date obligatoire" }}
              />
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={8}>
              <CustomInput
                label="Libellé"
                inputName="libelle"
                formItemName="libelle"
                rules={{ required: true, message: "Libellé obligatoire" }}
              />
            </Col>
          </Row>
          <Row gutter={12}>
            <Col offset={6} span={2} align="right">
              <Button
                type="primary"
                htmlType="submit"
                disabled={selectedRows.length === 0}
                style={{ marginTop: 20 }}
              >
                Validation
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default FormHonoraires;

import React, { useRef, useState } from "react";
import { Button, Col, Divider, Form, Modal, Row, Select, Switch, Table, Typography } from "antd";
import CustomSelect from "../CustomFormItem/Select";
import Constants from "../../utils/constants";
import { formatDateToBDD, getInfoLocataireSimple } from "../../utils/tools";

import { toast } from "react-toastify";

const { Title } = Typography;
const { Option, OptGroup } = Select;

const ListDemandes = (props) => {
  const { listData, qualites, locataires, provenances, groupes, parcoursClients, tableOption } =
    props;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [create, setCreate] = useState(false);
  const [demande, setDemande] = useState(null);
  const [locataire, setLocataire] = useState(null);
  const [typeContrat, setTypeContrat] = useState(null);
  const [provenance, setProvenance] = useState(null);
  const [groupe, setGroupe] = useState(null);

  const componentRef = useRef();

  const onClickValidation = (id) => {
    const demande = listData.find((data) => data.id === id);
    const locataire =
      demande &&
      locataires.find((locataire) => locataire.locataire_personne.personne_email1 === demande.mail);
    locataire ? onSetLocataire(locataire.locataire_id) : onSetLocataire(null);
    setDemande(demande);
    setModal(true);
  };

  const onSetLocataire = (id) => {
    form.setFieldsValue({
      locataire_id: id,
      create: id ? false : true,
      type_contrat: props.parametre.parametre_type_contrat,
      provenance_id: props.parametre.parametre_provenance_id,
      groupe_id: props.parametre.parametre_groupe_id,
    });
    setLocataire(id);
    setCreate(id ? false : true);
    setTypeContrat(props.parametre.parametre_type_contrat);
    setProvenance(props.parametre.parametre_provenance_id);
    setGroupe(props.parametre.parametre_groupe_id);
  };

  const onChangeCreate = (checked) => {
    setCreate(checked);
  };

  const onChangeTypeContrat = (value) => {
    setTypeContrat(value);
  };

  const onChangeProvenance = (value) => {
    setProvenance(value);
  };

  const onChangeGroupe = (value) => {
    setGroupe(value);
  };

  // const onConfirmCreate = () => {
  //   form.submit();
  // };

  const handleOnFinish = (data) => {
    if (demande.lots) {
      setLoading(true);
      handleCreateLocataire(data);
    } else {
      toast.error("Aucun lot trouvé !", { containerId: "A" });
    }
  };

  const handleCreateLocataire = (data) => {
    if (create)
      props
        .saveLocataire({
          compte_bancaire_type_paiement_id: 1,
          locataire_code: generateCodeLocataire(),
          personne_actif: true,
          personne_groupement_id: props.utilisateur.groupement_id,
          personne_nom: demande.nom,
          personne_prenom: demande.prenom,
          personne_qualite_id: qualites[0].qualite_id,
          personne_email1: demande.mail,
          personne_portable1: demande.telephone,
          personne_langue_id: props.parametre.parametre_langue_id,
        })
        .then((res) => {
          createRequete(demande, data, res.locataire_id);
        });
    else createRequete(demande, data, data.locataire_id);
  };

  const generateCodeLocataire = () => {
    let maxCode = 0;
    let maxNum = 0;
    let code = "";
    props.locataires &&
      props.locataires.forEach((item) => {
        if (parseInt(item.locataire_code) > parseInt(maxCode)) maxCode = item.locataire_code;
      });
    maxNum = parseInt(maxCode) + 1;
    for (var i = 0; i < 6 - maxNum.toString().length; i++) {
      code = code + "0";
    }
    return code + maxNum;
  };

  const createRequete = (demande, data, id) => {
    props.saveDossier(getJsonDossier()).then((dossier) => {
      props
        .saveRequete({
          requete_locataire_id: id,
          requete_provenance_id: data.provenance_id,
          requete_groupe_id: data.groupe_id,
          requete_dossier_id: dossier ? dossier.dossier_id : null,
          requete_commentaire: demande.remarques,
        })
        .then((requete) => {
          // // renduA
          if (parcoursClients.length > 0) {
            let firstPC = parcoursClients.find(
              (i) =>
                i.parcours_client_ordre === 1 &&
                i.parcours_client_groupe.groupe_id === data.groupe_id
            );
            data.groupe_id &&
              props.saveRenduA({
                rendu_a_actif: true,
                rendu_a_commentaire: "",
                rendu_a_requete_id: requete.requete_id,
                rendu_a_etape_id: firstPC.parcours_client_etape.etape_id,
              });
          }
          // recherche
          props.saveRecherche({
            recherche_alur: data.type_contrat === 1,
            recherche_long_terme: data.type_contrat === 2,
            recherche_court_terme: data.type_contrat === 3,
            recherche_date_debut: demande.date_debut,
            recherche_date_fin: demande.date_fin,
            recherche_requete_id: requete.requete_id,
            recherche_equipement: [],
            recherche_quartier: [],
            recherche_metro: [],
          });
          // requetesLots
          demande.lots.split(";").forEach((lot) => {
            props.lots?.some((item) => item.lot_id === parseInt(lot)) &&
              props.saveRequeteLot({
                requete_lot_requete_id: requete.requete_id,
                requete_lot_lot_id: lot,
                requete_lot_etat: "",
                requete_lot_type: 2,
              });
          });
          // dossier
          props
            .updateDossier(getJsonDossier(requete), dossier.dossier_id)
            .then((dos) => (requete.requete_dossier = dos));

          // location
          if (demande.montant) {
            props.saveLocation(getJsonLocation(demande, requete)).then((location) => {
              const lot = props.lots?.find((lot) => lot.lot_id === parseInt(demande.lots));

              if (lot) {
                const lignes = [];
                const ligne10 = getLigneRubrique(lot, location, "10");
                const ligne20 = getLigneRubrique(lot, location, "20");

                ligne10 && lignes.push(ligne10);
                ligne20 && lignes.push(ligne20);
                const rubriqueLoyer = props.rubriques?.find(
                  (rubrique) => rubrique.rubrique_code === "01"
                );

                if (rubriqueLoyer) {
                  const total = lignes.reduce(
                    (a, b) => a + parseFloat(b.location_ligne_montant),
                    0
                  );
                  lignes.push({
                    location_ligne_location_id: location.location_id,
                    location_ligne_pourcentage_acompte: 0,
                    location_ligne_montant: parseFloat(demande.montant - total),
                    location_ligne_rubrique_id: rubriqueLoyer.rubrique_id,
                    location_ligne_permanente: rubriqueLoyer.rubrique_permanente,
                  });
                }
                lignes.forEach((ligne) => {
                  ligne && props.saveLocationLigne(ligne);
                });
              }
            });
          }

          const pane = { key: "26", title: "Contrats / Disponibilités", content: "" };
          props.addPanesRequete(pane, "26", requete);
          props.deleteDemande(demande.id);

          setModal(false);
          setLoading(false);
        });
    });
  };

  const getJsonDossier = (requete) => {
    return {
      dossier_url: requete ? `Requetes/${requete.requete_id}/` : Constants.directory,
    };
  };

  const getJsonLocation = (demande, requete) => {
    return {
      location_date_creation: formatDateToBDD(),
      location_date_debut: demande.date_debut,
      location_date_fin: demande.date_fin,
      location_acompte_propose: 0.0,
      location_remarques: "",
      location_desiderata: "",
      location_fin_option: "/",
      location_etat: "o",
      location_nb_bebes: 0,
      location_nb_adultes: 0,
      location_nb_enfants: 0,
      location_montant_propose: 0,
      location_pourcentage_acompte: 0,
      location_heure_arrivee: "10:00",
      location_heure_depart: "11:45",
      location_entree: true,
      location_sortie: false,
      location_menage: true,
      location_type_contrat: null,
      location_remise_fidelite: true,
      location_lot_id: parseInt(demande.lots),
      location_requete_id: requete.requete_id,
      location_bail_signe: false,
      location_info_sejour: false,
      location_chauffeur_reserve: false,
    };
  };

  const getLigneRubrique = (lot, location, code) => {
    const ligne = lot.lot_prix_base.find(
      (ligne) => ligne.prix_base_rubrique.rubrique_code === code
    );
    return ligne
      ? {
          location_ligne_location_id: location.location_id,
          location_ligne_pourcentage_acompte: parseFloat(
            ligne.prix_base_rubrique.rubrique_pourcentage_acompte
          ),
          location_ligne_montant: parseFloat(ligne.prix_base_montant),
          location_ligne_rubrique_id: ligne.prix_base_rubrique.rubrique_id,
          location_ligne_permanente: ligne.prix_base_rubrique.rubrique_permanente,
        }
      : null;
  };

  const onClickSuppression = (id) => {
    props.deleteDemande(id);
  };

  return (
    <>
      <Modal
        title="Validation de la demande"
        width={800}
        open={modal}
        onCancel={() => setModal(false)}
        footer={null}
      >
        <Form form={form} name="demande" onFinish={handleOnFinish}>
          <>
            <Form.Item name="create" valuePropName="checked">
              <Switch
                checked={create}
                onChange={onChangeCreate}
                style={{ height: "40px" }}
                checkedChildren="Nouveau locataire"
                unCheckedChildren="Nouveau locataire"
              />
            </Form.Item>
          </>
          {!create && (
            <>
              <Divider orientation="left">Sélectionner un locataire existant</Divider>
              <CustomSelect
                label="Locataire"
                inputName="locataire_id"
                formItemName="locataire_id"
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                objectValue={locataire}
              >
                <OptGroup label="Locataire">
                  {locataires &&
                    locataires.map((item, i) => {
                      return (
                        <Option
                          key={i}
                          value={item.locataire_id}
                          label={getInfoLocataireSimple(item)}
                        >
                          <Row>
                            <Col sm={8}>{getInfoLocataireSimple(item)}</Col>
                          </Row>
                        </Option>
                      );
                    })}
                </OptGroup>
              </CustomSelect>
            </>
          )}
          <>
            <Divider orientation="left">Provenances & groupes</Divider>
            <CustomSelect
              label="Type de contrat"
              inputName="type_contrat"
              formItemName="type_contrat"
              onChange={onChangeTypeContrat}
              objectValue={typeContrat}
            >
              <OptGroup label="Type de contrat">
                <Option key={1} value={1} label={"Alur"}>
                  Alur
                </Option>
                <Option key={2} value={2} label={"Long terme"}>
                  Long terme
                </Option>
                <Option key={3} value={3} label={"Court terme"}>
                  Court terme
                </Option>
              </OptGroup>
            </CustomSelect>
            <CustomSelect
              label="Provenance"
              inputName="provenance_id"
              formItemName="provenance_id"
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              allowClear
              onChange={onChangeProvenance}
              objectValue={provenance}
            >
              <OptGroup label="Provenance">
                {provenances &&
                  provenances.map((item, i) => {
                    return (
                      <Option key={i} value={item.provenance_id} label={item.provenance_libelle}>
                        <Row>
                          <Col sm={8}>{item.provenance_libelle}</Col>
                        </Row>
                      </Option>
                    );
                  })}
              </OptGroup>
            </CustomSelect>
            <CustomSelect
              label="Groupe"
              inputName="groupe_id"
              formItemName="groupe_id"
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              allowClear
              onChange={onChangeGroupe}
              objectValue={groupe}
            >
              <OptGroup label="Groupe">
                {groupes &&
                  groupes
                    .filter((groupe) => groupe.groupe_visible === true)
                    .map((item, i) => {
                      return (
                        <Option key={i} value={item.groupe_id} label={item.groupe_libelle}>
                          <Row>
                            <Col sm={8}>{item.groupe_libelle}</Col>
                          </Row>
                        </Option>
                      );
                    })}
              </OptGroup>
            </CustomSelect>
          </>
          <div style={{ marginTop: 10, textAlign: "right" }}>
            <Button type="primary" htmlType="submit" loading={loading} style={{ marginRight: 10 }}>
              Valider
            </Button>
          </div>
        </Form>
      </Modal>
      <div style={{ padding: 20 }}>
        <Row>
          <Title level={2}>Liste des demandes</Title>
        </Row>
      </div>
      <div ref={componentRef}>
        {listData && (
          <Table
            className="list-data"
            dataSource={listData}
            columns={tableOption(props.lots, onClickValidation, onClickSuppression)}
            pagination={false}
            rowKey="id"
          />
        )}
      </div>
    </>
  );
};

export default ListDemandes;

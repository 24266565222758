import React, { Component } from "react";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Tabs,
  Table,
  Checkbox,
  Modal,
  Spin,
} from "antd";

import { RedoOutlined, EyeOutlined } from "@ant-design/icons";

import { toast } from "react-toastify";

import PlacesAutocomplete, { geocodeByAddress } from "react-places-autocomplete";

import FloatLabel from "../FloatLabel";
import CustomInput from "../CustomFormItem/Input";
import CustomSelect from "../CustomFormItem/Select";
import CustomDatePicker from "../CustomFormItem/DatePicker";
import { columns, columnsProprietaire } from "../../containers/Locations/columns";

import FormCompteBancaire from "../FormCompteBancaire";
import FormDocument from "../FormDocument";
import ListLocation from "../ListLocation";
import TableSituation from "../TableSituation";
import TableEvenements from "../TableEvenements";
import FormMail from "../FormMail";

import Constants from "../../utils/constants";

import dayjs from "dayjs";

const { Option, OptGroup } = Select;

class FormPersonneLarge extends Component {
  state = {
    key: "1",
    loading: false,
    loadingPdf: false,
    lignesEcritures: [],
    lignesEvenements: [],
    dossier: null,
    document: null,
    modele: null,
    idPdf: null,
    documentWord: null,
    documentPdf: null,
    modalPdf: false,
    // societe: "",
    periodiciteReleve: "",
    periodiciteAcompte: "",
    montantAcompte: "",
    typeAcompte: "",
    adresse: "",
    numero: "",
    voie: "",
    complement: "",
    cp: "",
    ville: "",
    departement: "",
    region: "",
    pays: "",
  };

  // Référence du formulaire
  formRef = React.createRef();

  componentDidMount() {
    this.props.personne
      ? this.initPersonne(this.props.personne, this.props.code, this.props.evenements)
      : this.resetPersonne(this.props.code);
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (
      this.props.personne !== props.personne ||
      this.props.code !== props.code ||
      this.props.evenements !== props.evenements
    ) {
      props.personne
        ? this.initPersonne(props.personne, props.code, props.evenements)
        : this.resetPersonne(props.code);
    }
  }

  initPersonne = (data, code, evenements) => {
    this.resetPersonne(code);

    const personne = this.props.proprietaire ? data.proprietaire_personne : data.locataire_personne;
    const affectations = this.props.proprietaire
      ? data.proprietaire_affectations
      : data.locataire_affectations;

    const adresse = this.props.proprietaire
      ? data.proprietaire_personne.personne_adresse
      : data.locataire_personne.personne_adresse;
    const compte = this.props.proprietaire
      ? data.proprietaire_personne.personne_compte_bancaire
      : data.locataire_personne.personne_compte_bancaire;
    // const etablissement = this.props.proprietaire
    //   ? data.proprietaire_personne.personne_etablissement
    //   : data.locataire_personne.personne_etablissement;

    // recherche langue par défaut
    const langueParametre = this.props.parametre.parametre_langue_id;
    const langueDefaut =
      langueParametre && !this.props.proprietaire
        ? this.props.langues.find((langue) => langue.langue_id === langueParametre)
        : this.props.langues.find((l) => l.langue_code === "FR" || l.langue_code === "fr");

    this.formRef.current.setFieldsValue({
      // personne_societe_id: etablissement.etablissement_societe_id,
      // personne_etablissement_id: etablissement.etablissement_id,
      personne_nom: personne.personne_nom,
      personne_prenom: personne.personne_prenom,
      personne_tel1: personne.personne_tel1,
      personne_tel2: personne.personne_tel2,
      personne_portable1: personne.personne_portable1,
      personne_portable2: personne.personne_portable2,
      personne_email1: personne.personne_email1,
      personne_email2: personne.personne_email2,
      personne_commentaire: personne.personne_commentaire,
      personne_date_naissance: personne.personne_date_naissance
        ? dayjs(personne.personne_date_naissance)
        : null,
      personne_lieu_naissance: personne.personne_lieu_naissance,
      personne_qualite_id: personne.personne_qualite ? personne.personne_qualite.qualite_id : null,
      personne_langue_id: personne.personne_langue
        ? personne.personne_langue.langue_id
        : langueDefaut.langue_id,
      adresse_gmaps_adresse: adresse?.adresse_gmaps_adresse,
      adresse_numero: adresse?.adresse_numero,
      adresse_voie: adresse?.adresse_voie,
      adresse_situation: adresse?.adresse_situation,
      adresse_code_postal: adresse?.adresse_code_postal,
      adresse_ville: adresse?.adresse_ville,
      adresse_departement: adresse?.adresse_departement,
      adresse_pays: adresse?.adresse_pays,
      compte_bancaire_titulaire:
        compte && compte.compte_bancaire_titulaire
          ? compte.compte_bancaire_titulaire
          : `${personne.personne_nom} ${personne.personne_prenom ? personne.personne_prenom : ""}`,
      compte_bancaire_banque: compte && compte.compte_bancaire_banque,
      compte_bancaire_code_banque: compte && compte.compte_bancaire_code_banque,
      compte_bancaire_code_guichet: compte && compte.compte_bancaire_code_guichet,
      compte_bancaire_num_compte: compte && compte.compte_bancaire_num_compte,
      compte_bancaire_cle_RIB: compte && compte.compte_bancaire_cle_RIB,
      compte_bancaire_IBAN: compte && compte.compte_bancaire_IBAN,
      compte_bancaire_BIC: compte && compte.compte_bancaire_BIC,
      compte_bancaire_type_paiement_id:
        compte && compte.compte_bancaire_type_paiement.type_paiement_id,
    });

    this.props.proprietaire
      ? this.formRef.current.setFieldsValue({
          proprietaire_code: data.proprietaire_code,
          proprietaire_password_extranet: data.proprietaire_password_extranet,
          proprietaire_frequence_releve: data.proprietaire_frequence_releve,
          proprietaire_frequence_acompte: data.proprietaire_frequence_acompte,
          proprietaire_valeur_acompte: data.proprietaire_valeur_acompte,
          proprietaire_type_acompte: data.proprietaire_type_acompte,
          email_destinataire: personne.personne_email1,
          proprietaire_longue_duree: data.proprietaire_longue_duree,
        })
      : this.formRef.current.setFieldsValue({
          locataire_code: data.locataire_code,
          locataire_provenance_id: data.locataire_provenance
            ? data.locataire_provenance.provenance_id
            : null,
        });

    const lignesEcritures = [];
    let dossier = null;
    if (affectations?.length > 0) {
      this.props.ecritures &&
        this.props.ecritures.forEach((ecriture) => {
          const lignes = ecriture.ecriture_ligne.filter(
            (e) =>
              e.ecriture_ligne_compte.compte_id ===
              (this.props.proprietaire
                ? affectations[0].proprietaire_affectation_compte_id
                : affectations[0].locataire_affectation_compte_id)
          );
          lignes &&
            lignes.forEach((ligne) => {
              ligne.ecriture_id = ecriture.ecriture_id;
              ligne.ecriture_journal_code = ecriture.ecriture_journal.journal_code;
              ligne.ecriture_date_ecriture = ecriture.ecriture_date_ecriture;
              lignesEcritures.push(ligne);
            });
        });
      dossier = this.props.proprietaire ? affectations[0].proprietaire_affectation_dossier : null;
    }

    if (evenements) {
      const lignesEvenements = evenements
        .filter((evenement) => evenement.evenement_personne.personne_id === personne.personne_id)
        .sort((a, b) => b.evenement_date_creation - a.evenement_date_creation);
      this.setState({ lignesEvenements });
    }

    this.setState({
      key: this.props.tabCompta ? "4" : this.state.key,
      loading: this.props.loading,
      lignesEcritures,
      dossier,
      document: null,
      // societe: etablissement.etablissement_societe_id,
      periodiciteReleve: this.props.proprietaire ? data.proprietaire_frequence_releve : null,
      periodiciteAcompte: this.props.proprietaire ? data.proprietaire_frequence_acompte : null,
      montantAcompte: this.props.proprietaire ? data.proprietaire_valeur_acompte : null,
      typeAcompte: this.props.proprietaire ? data.proprietaire_type_acompte : null,
      adresse: adresse?.adresse_gmaps_adresse,
      numero: adresse?.adresse_numero,
      voie: adresse?.adresse_voie,
      complement: adresse?.adresse_situation,
      cp: adresse?.adresse_code_postal,
      ville: adresse?.adresse_ville,
      departement: adresse?.adresse_departement,
      region: adresse?.adresse_region,
      pays: adresse?.adresse_pays,
    });
  };

  resetPersonne = (code) => {
    this.formRef.current.resetFields([
      // "personne_societe_id",
      // "personne_etablissement_id",
      "personne_nom",
      "personne_prenom",
      "personne_tel1",
      "personne_tel2",
      "personne_portable1",
      "personne_portable2",
      "personne_email1",
      "personne_email2",
      "personne_commentaire",
      "personne_qualite_id",
      "personne_langue_id",
      "adresse_gmaps_adresse",
      "adresse_numero",
      "adresse_voie",
      "adresse_situation",
      "adresse_code_postal",
      "adresse_ville",
      "adresse_departement",
      "adresse_region",
      "adresse_pays",
      "compte_bancaire_titulaire",
      "compte_bancaire_banque",
      "compte_bancaire_code_banque",
      "compte_bancaire_code_guichet",
      "compte_bancaire_num_compte",
      "compte_bancaire_cle_RIB",
      "compte_bancaire_IBAN",
      "compte_bancaire_BIC",
      "compte_bancaire_type_paiement_id",
    ]);

    this.props.proprietaire
      ? this.formRef.current.resetFields([
          "proprietaire_code",
          "proprietaire_password_extranet",
          "proprietaire_frequence_releve",
          "proprietaire_frequence_acompte",
          "proprietaire_valeur_acompte",
          "proprietaire_type_acompte",
          "proprietaire_longue_duree",
        ])
      : this.formRef.current.resetFields(["locataire_code", "locataire_provenance_id"]);

    // recherche langue par défaut
    const langueParametre = this.props.parametre.parametre_langue_id;
    const langueDefaut = langueParametre
      ? this.props.langues.find((langue) => langue.langue_id === langueParametre)
      : this.props.langues.find((l) => l.langue_code === "FR" || l.langue_code === "fr");

    this.props.proprietaire
      ? this.formRef.current.setFieldsValue({
          proprietaire_code: code,
          proprietaire_type_acompte: "%",
        })
      : this.formRef.current.setFieldsValue({
          locataire_code: code,
          personne_langue_id: langueDefaut.langue_id,
        });

    this.setState({
      loading: this.props.loading,
      lignesEcritures: [],
      lignesEvenements: [],
      // societe: "",
      periodiciteReleve: "",
      periodiciteAcompte: "",
      montantAcompte: "",
      typeAcompte: "%",
      adresse: "",
      numero: "",
      voie: "",
      complement: "",
      cp: "",
      ville: "",
      departement: "",
      region: "",
      pays: "",
    });
  };

  onFinish = (data) => {
    const personne = this.props.personne
      ? this.props.proprietaire
        ? this.props.personne.proprietaire_personne
        : this.props.personne.locataire_personne
      : null;
    data.personne_actif = true;
    data.personne_adresse_id = personne ? personne.personne_adresse.adresse_id : null;
    data.personne_groupement_id = personne
      ? personne.personne_groupement.groupement_id
      : this.props.utilisateur.groupement_id;
    data.personne_compte_bancaire_id = personne
      ? personne.personne_compte_bancaire.compte_bancaire_id
      : null;
    data.compte_bancaire_type_paiement_id = data.compte_bancaire_type_paiement_id
      ? data.compte_bancaire_type_paiement_id
      : personne
      ? personne.personne_compte_bancaire.compte_bancaire_type_paiement.type_paiement_id
      : 1;
    data.compte_bancaire_titulaire = data.compte_bancaire_titulaire
      ? data.compte_bancaire_titulaire
      : personne
      ? personne.personne_compte_bancaire.compte_bancaire_titulaire
      : "";
    data.compte_bancaire_banque = data.compte_bancaire_banque
      ? data.compte_bancaire_banque
      : personne
      ? personne.personne_compte_bancaire.compte_bancaire_banque
      : "";
    data.compte_bancaire_IBAN = data.compte_bancaire_IBAN
      ? data.compte_bancaire_IBAN
      : personne
      ? personne.personne_compte_bancaire.compte_bancaire_IBAN
      : "";
    data.compte_bancaire_BIC = data.compte_bancaire_BIC
      ? data.compte_bancaire_BIC
      : personne
      ? personne.personne_compte_bancaire.compte_bancaire_BIC
      : "";

    this.setState({ loading: true });

    this.props.personne
      ? this.props.handleEdit(
          data,
          this.props.proprietaire
            ? this.props.personne.proprietaire_id
            : this.props.personne.locataire_id,
          this.props.proprietaire
        )
      : this.props.handleSubmit(data, this.props.proprietaire);
  };

  // onChangeSociete = (id) => {
  //   this.setState({ societe: id });
  // };

  handleChangeAdresse = (adresse) => {
    this.setState({ adresse });
  };

  handleSelectAdresse = (adresse) => {
    this.formRef.current.setFieldsValue({
      adresse_gmaps_adresse: adresse,
      adresse_numero: "",
      adresse_voie: "",
      adresse_ville: "",
      adresse_code_postal: "",
      adresse_region: "",
      adresse_departement: "",
      adresse_pays: "",
      adresse_situation: "",
    });
    geocodeByAddress(adresse).then((results) => {
      results[0].address_components.forEach((component, i) => {
        if (component.types[0] === "street_number") {
          this.setState({ numero: component.long_name });
          this.formRef.current.setFieldsValue({
            adresse_numero: component.long_name,
          });
        }
        if (component.types[0] === "route") {
          this.setState({ voie: component.long_name });
          this.formRef.current.setFieldsValue({
            adresse_voie: component.long_name,
          });
        }
        if (component.types[0] === "locality") {
          this.setState({ ville: component.long_name });
          this.formRef.current.setFieldsValue({
            adresse_ville: component.long_name,
          });
        }
        if (component.types[0] === "postal_code") {
          this.setState({ cp: component.long_name });
          this.formRef.current.setFieldsValue({
            adresse_code_postal: component.long_name,
          });
        }
        if (component.types[0] === "administrative_area_level_1") {
          this.setState({ region: component.long_name });
          this.formRef.current.setFieldsValue({
            adresse_region: component.long_name,
          });
        }
        if (component.types[0] === "administrative_area_level_2") {
          this.setState({ departement: component.long_name });
          this.formRef.current.setFieldsValue({
            adresse_departement: component.long_name,
          });
        }
        if (component.types[0] === "country") {
          this.setState({ pays: component.long_name });
          this.formRef.current.setFieldsValue({
            adresse_pays: component.long_name,
          });
        }
      });
    });
    this.setState({ adresse });
  };

  onClickAfficher = (id) => {
    const appartement = this.props.lotsFilter.find((item) => item.lot_id === id);
    this.props.afficherAppartement(appartement);
  };

  onChangeNom = (e) => {
    this.formRef.current.setFieldsValue({
      personne_nom: e.target.value.toUpperCase(),
    });
  };

  onChangeTab = (key, document) => {
    this.setState({ key, document });
  };

  checkCode = (e) => {
    let code = "";
    for (var i = 0; i < 6 - e.target.value.toString().length; i++) {
      code = code + "0";
    }
    this.props.proprietaire
      ? this.formRef.current.setFieldsValue({ proprietaire_code: code + e.target.value })
      : this.formRef.current.setFieldsValue({ locataire_code: code + e.target.value });
  };

  checkCodeUnicity = (_, value) => {
    if (
      !this.props.codeExist(
        value,
        this.props.proprietaire
          ? this.props.personne?.proprietaire_id
          : this.props.personne?.locataire_id
      )
    ) {
      return Promise.resolve();
    }
    return Promise.reject(new Error("Code déjà existant"));
  };

  onSelectModele = (modele) => {
    this.setState({ modele });
  };

  onClearModele = () => {
    this.setState({ modele: null });
  };

  handleCreatePdf = () => {
    if (this.state.modele) {
      const word = this.props.words.find((word) => word.word_id === this.state.modele);
      this.props.proprietaire ? this.createDocxPro(word) : this.createDocxLoc(word);
    } else {
      toast.error("Veuillez sélectionner un modèle", { containerId: "A" });
    }
    this.setState({ loadingPdf: true });
  };

  createDocxPro = (word) => {
    const { personne } = this.props;

    const dossier = personne.proprietaire_affectations[0].proprietaire_affectation_dossier;

    const strDate = dayjs().format("YYYY-MM-DD_HH-mm-ss");
    let documentName = `${this.props.utilisateur.utilisateur_etablissement.etablissement_societe.societe_raison_sociale}-${personne.proprietaire_personne.personne_nom}-${personne.proprietaire_personne.personne_prenom}-${strDate}`;
    documentName = documentName.replace("&", "").replace(/\s/g, "-");

    if (dossier) {
      const data = {
        document_nom: `${documentName}.pdf`,
        document_word_nom: `${documentName}.docx`,
        document_dossier_id: dossier.dossier_id,
        document_tag_id: word.word_tag_id ? word.word_tag_id : this.props.tags[0].tag_id,
        proprietaire_qualite: personne.proprietaire_personne.personne_qualite.qualite_libelle,
        proprietaire_nom: personne.proprietaire_personne.personne_nom,
        proprietaire_prenom: personne.proprietaire_personne.personne_prenom,
        proprietaire_adresse:
          personne.proprietaire_personne.personne_adresse.adresse_gmaps_adresse !== null
            ? personne.proprietaire_personne.personne_adresse.adresse_gmaps_adresse
            : "",
        proprietaire_cp: personne.proprietaire_personne.personne_adresse.adresse_code_postal,
        proprietaire_ville: personne.proprietaire_personne.personne_adresse.adresse_ville,
        proprietaire_tel: personne.proprietaire_personne.personne_tel1,
        proprietaire_tel2: personne.proprietaire_personne.personne_tel2,
        proprietaire_mail: personne.proprietaire_personne.personne_email1,
        proprietaire_mail2: personne.proprietaire_personne.personne_email2,
        proprietaire_adresse_numero: personne.proprietaire_personne.personne_adresse.adresse_numero,
        proprietaire_adresse_voie: personne.proprietaire_personne.personne_adresse.adresse_voie,
        proprietaire_adresse_complement:
          personne.proprietaire_personne.personne_adresse.adresse_situation,
        proprietaire_portable1: personne.proprietaire_personne.personne_portable1,
        proprietaire_portable2: personne.proprietaire_personne.personne_portable2,
        lot_photos: [],
      };

      this.props.processWord(data, word.word_id, dossier.dossier_id).then((res) => {
        this.setState({
          idPdf: null,
          documentWord: null,
          documentPdf: res,
          modalPdf: true,
          loadingPdf: false,
        });
      });
    } else {
      this.setState({ loadingPdf: false }, () =>
        toast.error("Dossier propriétaire introuvable", { containerId: "A" })
      );
    }
  };

  createDocxLoc = (word) => {
    const { utilisateur, personne } = this.props;

    const strDate = dayjs().format("YYYY-MM-DD_HH-mm-ss");
    let documentName = `${this.props.utilisateur.utilisateur_etablissement.etablissement_societe.societe_raison_sociale}-${personne.locataire_personne.personne_nom}-${personne.locataire_personne.personne_prenom}-${strDate}`;
    documentName = documentName.replace("&", "").replace(/\s/g, "-");

    if (utilisateur.dossier_id) {
      const data = {
        document_nom: `${documentName}.pdf`,
        document_word_nom: `${documentName}.docx`,
        document_dossier_id: utilisateur.dossier_id,
        document_tag_id: word.word_tag_id ? word.word_tag_id : this.props.tags[0].tag_id,
        locataire_qualite: personne.locataire_personne.personne_qualite.qualite_libelle,
        locataire_nom: personne.locataire_personne.personne_nom,
        locataire_prenom: personne.locataire_personne.personne_prenom,
        locataire_adresse:
          personne.locataire_personne.personne_adresse.adresse_gmaps_adresse !== null
            ? personne.locataire_personne.personne_adresse.adresse_gmaps_adresse
            : "",
        locataire_cp: personne.locataire_personne.personne_adresse.adresse_code_postal,
        locataire_ville: personne.locataire_personne.personne_adresse.adresse_ville,
        locataire_tel: personne.locataire_personne.personne_tel1,
        locataire_tel2: personne.locataire_personne.personne_tel2,
        locataire_mail: personne.locataire_personne.personne_email1,
        locataire_mail2: personne.locataire_personne.personne_email2,
        locataire_adresse_numero: personne.locataire_personne.personne_adresse.adresse_numero,
        locataire_adresse_voie: personne.locataire_personne.personne_adresse.adresse_voie,
        locataire_adresse_complement:
          personne.locataire_personne.personne_adresse.adresse_situation,
        locataire_portable1: personne.locataire_personne.personne_portable1,
        locataire_portable2: personne.locataire_personne.personne_portable2,
      };

      this.props.processWord(data, word.word_id, utilisateur.dossier_id).then((res) => {
        this.setState({
          idPdf: null,
          documentWord: null,
          documentPdf: res,
          modalPdf: true,
          loadingPdf: false,
        });
      });
    } else {
      this.setState({ loadingPdf: false }, () =>
        toast.error("Dossier société introuvable", { containerId: "A" })
      );
    }
  };

  closeModal = () => {
    this.setState({ modalPdf: false });
  };

  handleAnnulation = (document) => {
    // suppression du document
    this.props.deleteDocument(document.document_id);
    this.closeModal();
  };

  render() {
    const {
      readOnly,
      code,
      proprietaire,
      // societes,
      // etablissements,
      qualites,
      langues,
      provenances,
      typesPaiements,
      personne,
      etat,
      words,
    } = this.props;
    const {
      key,
      loading,
      loadingPdf,
      lignesEcritures,
      lignesEvenements,
      dossier,
      document,
      documentPdf,
      modalPdf,
      // societe,
      periodiciteReleve,
      periodiciteAcompte,
      montantAcompte,
      typeAcompte,
      adresse,
      numero,
      voie,
      complement,
      cp,
      ville,
      departement,
      region,
      pays,
    } = this.state;

    const data =
      personne && (proprietaire ? personne.proprietaire_personne : personne.locataire_personne);

    const slot = {
      right:
        key === "4" ? (
          <RedoOutlined
            style={{ fontSize: 24 }}
            onClick={() => this.props.loadEcritures(this.props.utilisateur.societe_id)}
          />
        ) : null,
    };

    const columnsLots = [
      {
        title: "Référence",
        dataIndex: "lot_reference",
        key: "lot_reference",
        width: "20%",
      },
      {
        title: "Désignation",
        dataIndex: "lot_designation",
        key: "lot_designation",
        width: "30%",
      },
      {
        title: "Adresse",
        dataIndex: "lot_adresse",
        key: "lot_adresse",
        width: "30%",
        render: (adresse) => adresse.adresse_gmaps_adresse,
      },
      {
        title: "Fiche Lot",
        dataIndex: "lot_id",
        key: "lot_id",
        render: (lot) => (
          <Button onClick={() => this.onClickAfficher(lot)}>
            <EyeOutlined />
          </Button>
        ),
        width: 100,
      },
    ];

    return (
      <>
        <Modal
          width={800}
          footer={null}
          title={"Propriétaire"}
          open={modalPdf}
          onCancel={this.closeModal}
        >
          <Spin spinning={loading} tip="Création pdf...">
            <Row gutter={12}>
              {documentPdf && (
                <Col span={6}>
                  <Button
                    style={{ width: "100%", marginBottom: 10 }}
                    href={`${Constants.directory}documents/${documentPdf.document_dossier.dossier_url}${documentPdf.document_tag.tag_libelle}/${documentPdf.document_nom}`}
                    target="_blank"
                  >
                    Télécharger PDF
                  </Button>
                </Col>
              )}
              {documentPdf && (
                <Col span={6}>
                  <Button
                    style={{ width: "100%", marginBottom: 10 }}
                    href={`${Constants.directory}documents/${
                      documentPdf.document_dossier.dossier_url
                    }${documentPdf.document_tag.tag_libelle}/${documentPdf.document_nom.replace(
                      ".pdf",
                      ".docx"
                    )}`}
                    target="_blank"
                  >
                    Télécharger Word
                  </Button>
                </Col>
              )}
              <Col span={6}>
                <Button
                  style={{ width: "100%", marginBottom: 10 }}
                  onClick={() => this.handleAnnulation(documentPdf)}
                >
                  Annuler
                </Button>
              </Col>
            </Row>
          </Spin>
        </Modal>

        <Form
          ref={this.formRef}
          onFinish={this.onFinish}
          size="large"
          className="form-requete"
          validateTrigger="onBlur"
        >
          <Tabs
            defaultActiveKey="1"
            activeKey={key}
            tabBarExtraContent={slot}
            onChange={(key) => this.setState({ key })}
            items={[
              {
                label: "Coordonnées",
                key: "1",
                children: (
                  <Row gutter={16}>
                    <Col span={12} style={{ padding: 20 }}>
                      <Divider orientation="left">Identification</Divider>
                      <Row gutter={8}>
                        <Col span={8}>
                          <CustomInput
                            label="Code"
                            inputName="code"
                            formItemName={proprietaire ? "proprietaire_code" : "locataire_code"}
                            objectValue={
                              personne
                                ? proprietaire
                                  ? personne.proprietaire_code
                                  : personne.locataire_code
                                : code
                            }
                            rules={{ validator: this.checkCodeUnicity }}
                            onBlur={this.checkCode}
                            // readOnly={true}
                          />
                        </Col>
                      </Row>
                      <Row gutter={8} style={{ marginTop: 20 }}>
                        <Col span={8}>
                          <CustomSelect
                            label="Qualité"
                            inputName="qualite"
                            formItemName="personne_qualite_id"
                            objectValue={
                              data && data.personne_qualite && data.personne_qualite.qualite_id
                            }
                            rules={{ required: true, message: "Qualité obligatoire" }}
                          >
                            <OptGroup label="Qualite">
                              {qualites &&
                                qualites.map((item, i) => {
                                  return (
                                    <Option
                                      key={i}
                                      value={item.qualite_id}
                                      label={item.qualite_libelle}
                                    >
                                      <Row>
                                        <Col sm={8}>{item.qualite_libelle}</Col>
                                      </Row>
                                    </Option>
                                  );
                                })}
                            </OptGroup>
                          </CustomSelect>
                        </Col>
                        <Col span={8}>
                          <CustomInput
                            label="Nom"
                            inputName="nom"
                            formItemName="personne_nom"
                            objectValue={data && data.personne_nom}
                            onChange={(e) => this.onChangeNom(e)}
                            rules={[
                              { required: true, message: "Nom obligatoire" },
                              {
                                pattern: /^[a-zA-Z0-9 ]*$/,
                                message: "Caractères spéciaux interdit",
                              },
                            ]}
                          />
                        </Col>
                        <Col span={8}>
                          <CustomInput
                            label="Prénom"
                            inputName="prenom"
                            formItemName="personne_prenom"
                            objectValue={data && data.personne_prenom}
                            rules={{
                              pattern: /^[a-zA-Z0-9 ]*$/,
                              message: "Caractères spéciaux interdit",
                            }}
                          />
                        </Col>
                      </Row>
                      <Row gutter={8} style={{ marginTop: 20 }}>
                        <Col span={12}>
                          <CustomInput
                            label="Email"
                            inputName="mail1"
                            formItemName="personne_email1"
                            objectValue={data && data.personne_email1}
                          />
                        </Col>
                        <Col span={12}>
                          <CustomInput
                            label="Email"
                            inputName="mail2"
                            formItemName="personne_email2"
                            objectValue={data && data.personne_email2}
                          />
                        </Col>
                        <Col span={6}>
                          <CustomInput
                            label="Portable"
                            inputName="portable1"
                            formItemName="personne_portable1"
                            objectValue={data && data.personne_portable1}
                          />
                        </Col>
                        <Col span={6}>
                          <CustomInput
                            label="Commentaire"
                            inputName="comportable1"
                            formItemName="personne_commentaire_portable1"
                            objectValue={data && data.personne_commentaire_portable1}
                          />
                        </Col>
                        <Col span={6}>
                          <CustomInput
                            label="Téléphone"
                            inputName="telephone1"
                            formItemName="personne_tel1"
                            objectValue={data && data.personne_tel1}
                          />
                        </Col>
                        <Col span={6}>
                          <CustomInput
                            label="Commentaire"
                            inputName="comtelephone1"
                            formItemName="personne_commentaire_tel1"
                            objectValue={data && data.personne_commentaire_tel1}
                          />
                        </Col>
                        <Col span={6}>
                          <CustomInput
                            label="Portable"
                            inputName="portable2"
                            formItemName="personne_portable2"
                            objectValue={data && data.personne_portable2}
                          />
                        </Col>
                        <Col span={6}>
                          <CustomInput
                            label="Commentaire"
                            inputName="comportable2"
                            formItemName="personne_commentaire_portable2"
                            objectValue={data && data.personne_commentaire_portable2}
                          />
                        </Col>
                        <Col span={6}>
                          <CustomInput
                            label="Téléphone"
                            inputName="telephone2"
                            formItemName="personne_tel2"
                            objectValue={data && data.personne_tel2}
                          />
                        </Col>
                        <Col span={6}>
                          <CustomInput
                            label="Commentaire"
                            inputName="comtelephone2"
                            formItemName="personne_commentaire_tel2"
                            objectValue={data && data.personne_commentaire_tel2}
                          />
                        </Col>
                      </Row>
                      <Divider orientation="left">Informations diverses</Divider>
                      <Row gutter={8}>
                        <Col span={8}>
                          <CustomDatePicker
                            label="Date de naissance"
                            inputName="date_naissance"
                            formItemName="personne_date_naissance"
                            objectValue={data && data.personne_date_naissance}
                          />
                        </Col>
                        <Col span={8}>
                          <CustomInput
                            label="Lieu naissance"
                            inputName="lieu_naissance"
                            formItemName="personne_lieu_naissance"
                            objectValue={data && data.personne_lieu_naissance}
                          />
                        </Col>
                        <Col span={8}>
                          <CustomSelect
                            label="Langue"
                            inputName="langue"
                            formItemName="personne_langue_id"
                            objectValue={
                              data
                                ? data.personne_langue
                                  ? data.personne_langue.langue_id
                                  : "default"
                                : proprietaire
                                ? null
                                : "default"
                            }
                          >
                            <OptGroup label="Langue">
                              {langues &&
                                langues.map((item, i) => {
                                  return (
                                    <Option
                                      key={i}
                                      value={item.langue_id}
                                      label={item.langue_libelle}
                                    >
                                      <Row>
                                        <Col sm={8}>{item.langue_libelle}</Col>
                                      </Row>
                                    </Option>
                                  );
                                })}
                            </OptGroup>
                          </CustomSelect>
                        </Col>
                      </Row>
                      <Row gutter={8}>
                        <Col span={16}>
                          <CustomInput
                            label="Remarques"
                            inputName="personne_commentaire"
                            formItemName="personne_commentaire"
                            objectValue={data && data.personne_commentaire}
                          />
                        </Col>
                        {!proprietaire && (
                          <Col span={8}>
                            <CustomSelect
                              label="Provenance"
                              inputName="provenance"
                              formItemName="locataire_provenance_id"
                              objectValue={
                                personne &&
                                personne.locataire_provenance &&
                                personne.locataire_provenance.provenance_id
                              }
                            >
                              <OptGroup label="Provenance">
                                {provenances &&
                                  provenances.map((item, i) => {
                                    return (
                                      <Option
                                        key={i}
                                        value={item.provenance_id}
                                        label={item.provenance_libelle}
                                      >
                                        <Row>
                                          <Col sm={8}>{item.provenance_libelle}</Col>
                                        </Row>
                                      </Option>
                                    );
                                  })}
                              </OptGroup>
                            </CustomSelect>
                          </Col>
                        )}
                      </Row>
                      {proprietaire && (
                        <div>
                          <Divider orientation="left">Traitements</Divider>
                          <Row>
                            <Col span={24}>
                              <FloatLabel
                                label="Périodicité relevé"
                                name="periodiciteReleve"
                                value={periodiciteReleve}
                              >
                                <Form.Item name="proprietaire_frequence_releve">
                                  <Select
                                    showSearch
                                    optionFilterProp="label"
                                    optionLabelProp="label"
                                    onChange={(value) =>
                                      this.setState({ periodiciteReleve: value })
                                    }
                                  >
                                    <OptGroup label="Périodicité">
                                      <Option value="mensuel" label="Mensuel">
                                        Mensuel
                                      </Option>
                                      <Option value="trimestriel" label="Trimestriel">
                                        Trimestriel
                                      </Option>
                                      <Option value="semestriel" label="Semestriel">
                                        Semestriel
                                      </Option>
                                      <Option value="annuel" label="Annuel">
                                        Annuel
                                      </Option>
                                      <Option value="sans" label="Sans">
                                        Sans
                                      </Option>
                                    </OptGroup>
                                  </Select>
                                </Form.Item>
                              </FloatLabel>
                            </Col>
                          </Row>
                          <Row gutter={8}>
                            <Col span={12}>
                              <FloatLabel
                                label="Périodicité acompte"
                                name="periodiciteAcompte"
                                value={periodiciteAcompte}
                              >
                                <Form.Item name="proprietaire_frequence_acompte">
                                  <Select
                                    showSearch
                                    optionFilterProp="label"
                                    optionLabelProp="label"
                                    onChange={(value) =>
                                      this.setState({ periodiciteAcompte: value })
                                    }
                                  >
                                    <OptGroup label="Périodicité">
                                      <Option value="mensuel" label="Mensuel">
                                        Mensuel
                                      </Option>
                                      <Option value="trimestriel" label="Trimestriel">
                                        Trimestriel
                                      </Option>
                                      <Option value="sans" label="Sans">
                                        Sans
                                      </Option>
                                    </OptGroup>
                                  </Select>
                                </Form.Item>
                              </FloatLabel>
                            </Col>
                            <Col span={7}>
                              <FloatLabel
                                label="Montant ou pourcentage"
                                name="montantAcompte"
                                value={montantAcompte}
                              >
                                <Form.Item name="proprietaire_montant_pourcentage">
                                  <Input
                                    value={montantAcompte}
                                    type="number"
                                    onChange={(e) =>
                                      this.setState({ montantAcompte: e.target.value })
                                    }
                                  />
                                </Form.Item>
                              </FloatLabel>
                            </Col>
                            <Col span={5}>
                              <FloatLabel
                                label="Type acompte"
                                name="typeAcompte"
                                value={typeAcompte}
                              >
                                <Form.Item name="proprietaire_type_acompte">
                                  <Select
                                    showSearch
                                    optionFilterProp="label"
                                    optionLabelProp="label"
                                    onChange={(value) => this.setState({ typeAcompte: value })}
                                  >
                                    <Option value="%" label="%">
                                      %
                                    </Option>
                                    <Option value="€" label="€">
                                      €
                                    </Option>
                                  </Select>
                                </Form.Item>
                              </FloatLabel>
                            </Col>
                          </Row>
                          <Form.Item name="proprietaire_longue_duree" valuePropName="checked">
                            <Checkbox>Longue durée ?</Checkbox>
                          </Form.Item>
                          <Form.Item name="proprietaire_password_extranet">
                            <Input hidden />
                          </Form.Item>
                        </div>
                      )}
                    </Col>
                    <Col span={12} style={{ padding: 20 }}>
                      <Divider orientation="left">Adresse</Divider>
                      <Row gutter={8}>
                        <Col span={24}>
                          <PlacesAutocomplete
                            value={adresse}
                            onChange={this.handleChangeAdresse}
                            onSelect={this.handleSelectAdresse}
                          >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                              <div>
                                <FloatLabel
                                  label="Recherche GOOGLE"
                                  name="adresse_gmaps_adresse"
                                  value={adresse}
                                >
                                  <Form.Item name="adresse_gmaps_adresse">
                                    <Input
                                      {...getInputProps({
                                        className: "location-search-input",
                                      })}
                                    />
                                  </Form.Item>
                                </FloatLabel>
                                <div className="autocomplete-dropdown-container">
                                  {loading && <div>Chargement ...</div>}
                                  {suggestions.map((suggestion, i) => {
                                    const className = suggestion.active
                                      ? "suggestion-item--active"
                                      : "suggestion-item";
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                      ? { backgroundColor: "#fafafa", cursor: "pointer" }
                                      : { backgroundColor: "#ffffff", cursor: "pointer" };
                                    return (
                                      <div
                                        {...getSuggestionItemProps(suggestion, {
                                          className,
                                          style,
                                        })}
                                        key={i}
                                      >
                                        <span>{suggestion.description}</span>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>
                        </Col>

                        <Col span={8}>
                          <FloatLabel label="N°" name="adresse_numero" value={numero}>
                            <Form.Item name="adresse_numero">
                              <Input
                                value={numero}
                                onChange={(e) => this.setState({ numero: e.target.value })}
                              />
                            </Form.Item>
                          </FloatLabel>
                        </Col>
                        <Col span={16}>
                          <FloatLabel label="Libellé voie" name="adresse_voie" value={voie}>
                            <Form.Item name="adresse_voie">
                              <Input
                                value={voie}
                                onChange={(e) => this.setState({ voie: e.target.value })}
                              />
                            </Form.Item>
                          </FloatLabel>
                        </Col>

                        <Col span={24}>
                          <FloatLabel
                            label="Complément adresse"
                            name="adresse_situation"
                            value={complement}
                          >
                            <Form.Item name="adresse_situation">
                              <Input
                                value={complement}
                                onChange={(e) => this.setState({ complement: e.target.value })}
                              />
                            </Form.Item>
                          </FloatLabel>
                        </Col>

                        <Col span={8}>
                          <FloatLabel label="CP" name="adresse_code_postal" value={cp}>
                            <Form.Item name="adresse_code_postal">
                              <Input
                                value={cp}
                                onChange={(e) => this.setState({ cp: e.target.value })}
                              />
                            </Form.Item>
                          </FloatLabel>
                        </Col>
                        <Col span={16}>
                          <FloatLabel label="Ville" name="adresse_ville" value={ville}>
                            <Form.Item name="adresse_ville">
                              <Input
                                value={ville}
                                onChange={(e) => this.setState({ ville: e.target.value })}
                              />
                            </Form.Item>
                          </FloatLabel>
                        </Col>

                        <Col span={8}>
                          <FloatLabel
                            label="Département"
                            name="adresse_departement"
                            value={departement}
                          >
                            <Form.Item name="adresse_departement">
                              <Input
                                value={departement}
                                onChange={(e) => this.setState({ departement: e.target.value })}
                              />
                            </Form.Item>
                          </FloatLabel>
                        </Col>
                        <Col span={8}>
                          <FloatLabel label="Région" name="adresse_region" value={region}>
                            <Form.Item name="adresse_region">
                              <Input
                                value={region}
                                onChange={(e) => this.setState({ region: e.target.value })}
                              />
                            </Form.Item>
                          </FloatLabel>
                        </Col>
                        <Col span={8}>
                          <FloatLabel label="Pays" name="adresse_pays" value={pays}>
                            <Form.Item name="adresse_pays">
                              <Input
                                value={pays}
                                onChange={(e) => this.setState({ pays: e.target.value })}
                              />
                            </Form.Item>
                          </FloatLabel>
                        </Col>
                      </Row>
                      {personne && (
                        <>
                          <Divider orientation="left">Éditions</Divider>
                          <Row gutter={10}>
                            <Col span={21}>
                              <Form.Item name="pdf_template">
                                <Select
                                  autoComplete="new-password"
                                  showSearch
                                  allowClear
                                  optionFilterProp="label"
                                  placeholder="Choix modèle"
                                  onSelect={this.onSelectModele}
                                  onClear={this.onClearModele}
                                >
                                  <OptGroup label="Modèle">
                                    {words &&
                                      words
                                        .filter((item) => (item.word_type === proprietaire ? 3 : 4))
                                        .map((item, i) => {
                                          return (
                                            <Option
                                              key={i}
                                              value={item.word_id}
                                              label={`${item.word_nom}`}
                                            >
                                              <Row>
                                                <Col sm={8}>{`${item.word_nom}`}</Col>
                                              </Row>
                                            </Option>
                                          );
                                        })}
                                  </OptGroup>
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col span={3}>
                              <Button
                                size="middle"
                                type="primary"
                                loading={loadingPdf}
                                onClick={() => this.handleCreatePdf()}
                              >
                                Générer PDF
                              </Button>
                            </Col>
                          </Row>
                        </>
                      )}
                    </Col>
                  </Row>
                ),
              },
              proprietaire && {
                label: "Informations financières",
                key: "2",
                children: (
                  <FormCompteBancaire
                    utilisateur={this.props.utilisateur}
                    personne={
                      personne &&
                      (proprietaire ? personne.proprietaire_personne : personne.locataire_personne)
                    }
                    compteBancaire={
                      personne &&
                      (proprietaire
                        ? personne.proprietaire_personne.personne_compte_bancaire
                        : personne.locataire_personne.personne_compte_bancaire)
                    }
                    typesPaiements={typesPaiements}
                  />
                ),
              },
              {
                label: proprietaire ? "Documents propriétaire" : "Documents locataire",
                key: "3",
                disabled: !personne ? true : false,
                children: (
                  <FormDocument
                    dossier={dossier}
                    fromRequete={false}
                    utilisateur={this.props.utilisateur}
                    documents={this.props.documents}
                    tags={this.props.tags}
                    typeTag={1}
                    saveDocument={this.props.saveDocument}
                    deleteDocument={this.props.deleteDocument}
                    saveTag={this.props.saveTag}
                    updateTag={this.props.updateTag}
                    deleteTag={this.props.deleteTag}
                    onChangeTab={this.onChangeTab}
                  />
                ),
              },
              {
                label: "Comptabilité",
                key: "4",
                disabled: !personne ? true : false,
                children: <TableSituation lignesEcritures={lignesEcritures} />,
              },
              !proprietaire && {
                label: "Historique contrats",
                key: 5,
                disabled: !personne ? true : false,
                children: (
                  <ListLocation
                    listData={this.props.locationsFiltered}
                    reglements={this.props.reglements}
                    quittances={this.props.quittances}
                    tableOption={etat === "b" ? columnsProprietaire : columns}
                    etat={"r"}
                    addPanesRequete={this.props.addPanesRequete}
                    titre={false}
                  />
                ),
              },
              proprietaire &&
                this.props.lotsFilter && {
                  label: "Lots",
                  key: "6",
                  disabled: !personne ? true : false,
                  children: <Table columns={columnsLots} dataSource={this.props.lotsFilter} />,
                },
              proprietaire && {
                label: "Courriel",
                key: "7",
                disabled: !personne ? true : false,
                children: (
                  <FormMail
                    utilisateur={this.props.utilisateur}
                    lot={{}}
                    data={data}
                    defaultEmail={data && data.personne_email1}
                    langues={this.props.langues}
                    templates={this.props.templates}
                    templatesLangues={this.props.templatesLangues}
                    document={document}
                    parametre={this.props.parametre}
                    saveEmail={this.props.saveEmail}
                    deleteEmail={this.props.deleteEmail}
                  />
                ),
              },
              {
                label: "Bloc-note",
                key: "8",
                disabled: !personne ? true : false,
                children: (
                  <TableEvenements
                    proprietaire={proprietaire}
                    personne={personne}
                    lignesEvenements={lignesEvenements}
                    saveEvenement={this.props.saveEvenement}
                    updateEvenement={this.props.updateEvenement}
                    deleteEvenement={this.props.deleteEvenement}
                  />
                ),
              },
            ]}
          />
          {!readOnly && parseInt(key) < "4" && (
            <Row style={{ marginTop: 10, float: "right" }}>
              <Button type="primary" danger>
                Annulation
              </Button>
              <Button type="primary" htmlType="submit" loading={loading} style={{ marginLeft: 10 }}>
                Validation
              </Button>
            </Row>
          )}
        </Form>
      </>
    );
  }
}

export default FormPersonneLarge;

import React, { useEffect, useState } from "react";

import FloatLabel from "../../FloatLabel";
import { Form, Input } from "antd";

const CustomInput = (props) => {
  const [value, setValue] = useState(false);
  const {
    label,
    formItemName,
    inputName,
    objectValue,
    type,
    suffix,
    onBlur,
    rules,
    readOnly,
    minLength,
    maxLength,
  } = props;

  useEffect(() => {
    objectValue ? setValue(objectValue) : setValue(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.objectValue]);

  const onChange = (e) => {
    props.onChange && props.onChange(e);
    setValue(e.target.value);
  };

  const processedRules = Array.isArray(rules) ? rules : [rules];

  return (
    <FloatLabel label={label} name={inputName} value={value}>
      <Form.Item name={formItemName} rules={processedRules}>
        <Input
          name={inputName}
          value={value}
          onChange={(e) => onChange(e)}
          type={type ? type : "text"}
          suffix={suffix ? suffix : ""}
          onBlur={onBlur}
          readOnly={readOnly ? readOnly : false}
          minLength={minLength}
          maxLength={maxLength}
        />
      </Form.Item>
    </FloatLabel>
  );
};

export default CustomInput;

import React, { Component } from "react";
import { connect } from "react-redux";

import {
  loadRubriques,
  saveRubrique,
  updateRubrique,
  deleteRubrique,
} from "../../store/actions/rubriqueAction";

import { Spin, Modal, Row, Button, Table, Popconfirm, Col } from "antd";

import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";

import FormRubrique from "../../components/FormRubrique";
import {
  deleteRubriquePourcentage,
  saveRubriquePourcentage,
} from "../../store/actions/rubriquePourcentageAction";
import { loadComptes } from "../../store/actions/compteAction";

const { Column } = Table;

class Rubriques extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rubrique: null,
      modalRubrique: false,
      loading: false,
    };
  }

  componentDidMount() {
    const etablissement_id = this.props.utilisateur.etablissement_id;
    const societe_id = this.props.utilisateur.societe_id;
    this.props.loadRubriques(etablissement_id);
    this.props.loadComptes(societe_id);
  }

  closeModalRubrique = () => {
    this.setState({ modalRubrique: false, last: null });
  };

  handleAddRubrique = () => {
    this.setState({
      rubrique: null,
      modalRubrique: true,
    });
  };

  onClickEditer = (rubrique) => {
    this.setState({
      rubrique: this.props.rubriques.filter((item) => item.rubrique_id === rubrique)[0],
      last: null,
      modalRubrique: true,
    });
  };

  handleSubmit = (data) => {
    data.rubrique_recapitulatif_ca = data.rubrique_recapitulatif_ca
      ? data.rubrique_recapitulatif_ca
      : false;
    data.rubrique_etablissement_id = this.props.utilisateur.etablissement_id;
    this.props.saveRubrique(data).then((res) => {
      this.closeModalRubrique();
    });
  };

  handleEdit = (data) => {
    data.rubrique_recapitulatif_ca = data.rubrique_recapitulatif_ca
      ? data.rubrique_recapitulatif_ca
      : false;
    data.rubrique_etablissement_id = this.props.utilisateur.etablissement_id;
    this.props.updateRubrique(data, this.state.rubrique.rubrique_id).then((res) => {
      this.closeModalRubrique();
    });
  };

  onConfirmDelete = (rubrique) => {
    this.props.deleteRubrique(rubrique);
  };

  render() {
    const { rubrique, modalRubrique, loading } = this.state;
    const { rubriques, comptes } = this.props;

    rubriques.sort((a, b) => parseInt(a.rubrique_code) - parseInt(b.rubrique_code));

    const last = String(
      parseInt(
        this.props.rubriques.length > 0
          ? [...this.props.rubriques].pop() !== undefined
            ? [...this.props.rubriques].pop().rubrique_code
            : 0
          : 0
      ) + 1
    ).padStart(2, "0");

    return (
      <Spin spinning={loading} size="large" tip="Chargement...">
        <Modal
          open={modalRubrique}
          onCancel={this.closeModalRubrique}
          title="Rubrique"
          width="800px"
          footer={null}
        >
          <FormRubrique
            last={last}
            rubrique={rubrique}
            comptes={comptes}
            rubriques={rubriques}
            saveRubriquePourcentage={this.props.saveRubriquePourcentage}
            deleteRubriquePourcentage={this.props.deleteRubriquePourcentage}
            handleSubmit={this.handleSubmit}
            handleEdit={this.handleEdit}
          />
        </Modal>
        <div style={{ padding: 10 }}>
          <Row>
            <Button
              onClick={this.handleAddRubrique}
              type="primary"
              style={{ float: "right", marginTop: 16, marginBottom: 16 }}
            >
              Nouvelle Rubrique
            </Button>
          </Row>
          <Row>
            <Col span={24}>
              <Table dataSource={rubriques} rowKey="rubrique_id">
                <Column title="Code" dataIndex="rubrique_code" key="rubrique_code" align="center" />
                <Column title="#" dataIndex="rubrique_id" key="rubrique_id" align="center" />
                <Column title="Libelle" dataIndex="rubrique_libelle" key="libelle" />
                <Column title="Type" dataIndex="rubrique_type" key="rubrique_type" align="center" />
                <Column
                  title="Signe"
                  dataIndex="rubrique_signe"
                  key="rubrique_signe"
                  align="center"
                />
                <Column
                  title="Compte"
                  dataIndex="rubrique_compte"
                  key="rubrique_compte"
                  render={(compte) =>
                    compte ? `${compte.compte_compte_general} - ${compte.compte_libelle}` : ""
                  }
                />
                <Column
                  title="Acompte (%)"
                  dataIndex="rubrique_pourcentage_acompte"
                  key="rubrique_pourcentage_acompte"
                  align="center"
                />
                <Column
                  title="Contrat"
                  dataIndex="rubrique_contrat"
                  key="rubrique_contrat"
                  render={(contrat) => (contrat ? "ok" : "")}
                />
                <Column
                  title="Montant fixe (€)"
                  dataIndex="rubrique_montant_defaut"
                  key="rubrique_montant_defaut"
                  align="right"
                />
                <Column
                  width={50}
                  title="Editer"
                  key="rubrique_id"
                  dataIndex="rubrique_id"
                  render={(rubrique) => (
                    <Button onClick={() => this.onClickEditer(rubrique)}>
                      <EyeOutlined />
                    </Button>
                  )}
                />
                <Column
                  width={50}
                  title="Supprimer"
                  key="rubrique_id"
                  dataIndex="rubrique_id"
                  render={(rubrique) => (
                    <Popconfirm
                      title="Supprimer cette rubrique ?"
                      onConfirm={() => this.onConfirmDelete(rubrique)}
                    >
                      <Button>
                        <DeleteOutlined />
                      </Button>
                    </Popconfirm>
                  )}
                />
              </Table>
            </Col>
          </Row>
        </div>
      </Spin>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadRubriques: (etablissement_id) => dispatch(loadRubriques(etablissement_id)),
    loadComptes: (societe_id) => dispatch(loadComptes(societe_id)),
    saveRubrique: (jsonData) => dispatch(saveRubrique(jsonData)),
    updateRubrique: (jsonData, id) => dispatch(updateRubrique(jsonData, id)),
    deleteRubrique: (id) => dispatch(deleteRubrique(id)),
    saveRubriquePourcentage: (jsonData, idPrincipale) =>
      dispatch(saveRubriquePourcentage(jsonData, idPrincipale)),
    deleteRubriquePourcentage: (idPrincipale, idLiee) =>
      dispatch(deleteRubriquePourcentage(idPrincipale, idLiee)),
  };
};

// mapper le state aux props du component
const mapStateToProps = (state) => {
  return {
    utilisateur: state.utilisateur.data,
    rubriques: state.rubriques.data,
    comptes: state.comptes.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Rubriques);
